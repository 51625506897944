import React, { useState }  from 'react';
import { useMsal } from "@azure/msal-react";
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, TextField, Link } from '@mui/material';
import { addMetric, getMetricByName, getMetrics } from '../services/api';
import errorMessageToDisplay from '../services/error';
import DropdownField from './DropdownField';
import error from '../services/error';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddMetricDetail = React.memo((props) => {
    const { setAdd, unitValueTypes, setIsLoading, setErrorMessage, sourceFields, setMetrics } = props;
    const { instance } = useMsal();

    const errorText = 'Field is required';
    const [metric, setMetric] = useState({});
    const [open, setOpen] = React.useState(true);
    const [nameIsValid, setNameIsValid] = useState(true);
    const [displayNameIsValid, setDisplayNameIsValid] = useState(true);
    const [valueUnitSourceIsValid, setValueUnitSourceIsValid] = useState(true);
    const [metricValueSourceFields, setMetricValueSourceFields] = useState([]);
    const [metricValueTypes, setMetricValueTypes] = useState([]);
    const [newValueUnitSource, setNewValueUnitSource] = useState(false);
    
    const [nameError, setNameError] = useState('');
    const [displayNameError, setDisplayNameError] = useState('');
    const [valueUnitTypeError, setValueUnitTypeError] = useState('');
    const [sourceProductSuiteError, setSourceProductSuiteError] = useState('');

    const [sourceDataProductMetricTypeCodeError, setSourceDataProductMetricTypeCodeError] = useState([]);
    const [metricValueTypeError, setMetricValueTypeError] = useState([]);

    // const [valueSourceError, setValueSourceError] = useState('');
    // const [coverageSourceError, setCoverageSourceError] = useState('');
    // const [eligibilitySourceError, setEligibilitySourceError] = useState('');

    function mapTypesToOptions(types) {
        return types.map((type) => ({
          label: type,
          value: type,
        }));
    }

    const handleFieldChange = (event, field) => {
        if(field === 'name' && nameIsValid === false) {
          setNameIsValid(true);
          setNameError('');
        }
        else if(field === 'displayName' && displayNameIsValid === false) {
          setDisplayNameIsValid(true);
          setDisplayNameError('');
        }
        else if(field === 'valueUnitType' && valueUnitSourceIsValid === false) {
          setValueUnitSourceIsValid(true);
          setValueUnitTypeError('');
        }
        else if(field === 'sourceProductSuite') {
          setSourceProductSuiteError('');
          updateSourcesToDisplay(event.target.value);
        }
        else if(field === 'metricValueType') {
          setMetricValueTypeError('');
        }
        else if(field === 'sourceDataProductMetricTypeCode') {
          setSourceDataProductMetricTypeCodeError('');
        }

        setMetric({
            ...metric,
            [field]:event.target.value
        });
        console.log(metric);
    }

    const fieldsAreValid = () => {
      let fieldsAreValid = true;
      if(metric.name == null || metric.name.trim() === '') {
        setNameIsValid(false);
        setNameError(errorText);
        fieldsAreValid = false;
      }
      if(metric.displayName == null || metric.displayName.trim() === '') {
        setDisplayNameIsValid(false);
        setDisplayNameError(errorText);
        fieldsAreValid = false;
      }
      if(metric.valueUnitType == null || metric.valueUnitType.trim() === '') {
        setValueUnitSourceIsValid(false);
        setValueUnitTypeError(errorText);        
        fieldsAreValid = false;
      }
      if(metric.sourceProductSuite == null || metric.sourceProductSuite.trim() === '') {
        setSourceProductSuiteError(errorText);
        fieldsAreValid = false;
      }
      if(metric.metricValueType == null || metric.metricValueType.trim() === '') {
        setMetricValueTypeError(errorText);
        fieldsAreValid = false;
      }
      if(metric.sourceDataProductMetricTypeCode == null || metric.sourceDataProductMetricTypeCode.trim() === '') {
        setSourceDataProductMetricTypeCodeError(errorText);
        fieldsAreValid = false;
      }
      return fieldsAreValid;
    }

    const handleSave = () => {
      console.log(metric);
      if(!fieldsAreValid()) {
        return;
      }
      getMetricByName(metric.name)
      .then((response) => {
        if(response.metricExists != null && response.metricExists === false) {
          setIsLoading(true);
          var trimmedMetric = Object.entries(metric).reduce((acc, [key, value]) => {
            acc[key] = typeof value === 'string' ? value.trim() : value;
            return acc;
          }, {});
          addMetric(trimmedMetric, instance.getActiveAccount().username)
          .then(() => {
            getMetrics()
            .then((response) => {
              setMetrics([...response]);

              setIsLoading(false);
              setAdd(false);
            }).catch((e) => {
              setIsLoading(false);
              setAdd(false);
              console.log(e)
              setErrorMessage(errorMessageToDisplay);
            })
          })
          .catch((e) => {
            setIsLoading(false);
            console.log(e)
            setErrorMessage(errorMessageToDisplay);
          }) 
        }
        else {          
          setNameIsValid(false);
          setNameError('Metric name already exists');
          return;          
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e)
        setErrorMessage(errorMessageToDisplay);
      })      
            
    }
  
    const handleClose = () => {
      setAdd(false);
    }

    const updateSourcesToDisplay = (sourceProductSuite) => {
      if(sourceProductSuite == null || sourceProductSuite.trim() === '') {
        setMetricValueSourceFields([]);
        setMetricValueTypes([]);
        return;
      }
      const filteredItems = sourceFields.filter((item) => item.sourceProductSuite === sourceProductSuite);
      setMetricValueSourceFields(filteredItems && filteredItems.length > 0 ? [...filteredItems[0].sourceFieldNames] : []);
      setMetricValueTypes(filteredItems && filteredItems.length > 0 ? [...filteredItems[0].metricValueTypes] : []);
    }

    const sourceProductSuitesToDisplay = (sourceFields) => {
      return sourceFields.map((sourceField) => sourceField.sourceProductSuite);
    }

    const metricValueTypesToDisplay = (sourceFields) => {
      return metricValueTypes;
    }
    
    const metricValueSourceFieldsToDisplay = (sourceFields) => {
      console.log(sourceFields);
      return metricValueSourceFields;
    }    

    const handleNewValueUnitClick = () => {
      setNewValueUnitSource(true);
    };
  
    const handleExistingValueUnitClick = () => {
      setNewValueUnitSource(false);
    };

    return (
        <>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
            maxWidth="lg"
          >
            <DialogTitle>{"Metric Detail"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3}><label><b>Name:</b></label></Grid>
                  <Grid item xs={12} sm={9}><TextField required error={!nameIsValid} helperText={nameError} value={metric.name != null ? metric.name : null} fullWidth onChange={(event) => handleFieldChange(event, 'name')}/></Grid>
                  <Grid item xs={12} sm={3}><label><b>Display Name:</b></label></Grid>
                  <Grid item xs={12} sm={9}><TextField required error={!displayNameIsValid} helperText={displayNameError} fullWidth onChange={(event) => handleFieldChange(event, 'displayName')}/></Grid>
                  {newValueUnitSource === true ?
                    <>
                      <Grid item xs={12} sm={3}><label><b>Value Unit Type:</b></label> <br/>                  
                        <Link href="#" onClick={handleExistingValueUnitClick} style={{ textAlign: 'center', alignSelf: 'flex-end' }}>
                          Existing
                        </Link>                
                      </Grid>  
                      <Grid item xs={12} sm={9}><TextField required error={!valueUnitSourceIsValid} helperText={valueUnitTypeError} fullWidth onChange={(event) => handleFieldChange(event, 'valueUnitType')}/></Grid>
                    </>
                  :
                    <>
                      <Grid item xs={12} sm={3}><label><b>Value Unit Type:</b></label> <br/>                  
                        <Link href="#" onClick={handleNewValueUnitClick} style={{ textAlign: 'center', alignSelf: 'flex-end' }}>
                          New
                        </Link>  
                      </Grid>
                      <Grid item xs={12} sm={9}><DropdownField options={mapTypesToOptions(unitValueTypes)} errorText={valueUnitTypeError} onChange={(event) => handleFieldChange(event, 'valueUnitType')} /></Grid>  
                    </>                    
                  }
                  <br/>
                  <br/>
                  <br/>
                  <Grid item sm={3}>
                    <h4>Source Fields</h4>
                  </Grid>
                  <Grid item sm={9}></Grid>
                  <Grid item xs={12} sm={3}><label><b>Source Product Suite:</b></label></Grid>
                  <Grid item xs={12} sm={9}><DropdownField options={mapTypesToOptions(sourceProductSuitesToDisplay(sourceFields))} errorText={sourceProductSuiteError} onChange={(event) => handleFieldChange(event, 'sourceProductSuite')} /></Grid>
                  <Grid item xs={12} sm={3}><label><b>Metric Value Source Field:</b></label></Grid>
                  <Grid item xs={12} sm={9}><DropdownField options={mapTypesToOptions(metricValueSourceFieldsToDisplay(sourceFields))} errorText={sourceDataProductMetricTypeCodeError} onChange={(event) => handleFieldChange(event, 'sourceDataProductMetricTypeCode')} /></Grid>
                  <Grid item xs={12} sm={3}><label><b>Metric Value Type:</b></label></Grid>
                  <Grid item xs={12} sm={9}><DropdownField options={mapTypesToOptions(metricValueTypesToDisplay(sourceFields))} errorText={metricValueTypeError} onChange={(event) => handleFieldChange(event, 'metricValueType')} /></Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>                    
          </Dialog>
        </>
      );

});

export default AddMetricDetail;