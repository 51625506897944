import useFetchWithMsal from './useFetchWithMsal'
import { protectedResources } from "../services/authConfig";
import {useCallback} from 'react';

export function useGetFundsApi() {
    const {scopes, endpoint} = protectedResources.fundApi;
    const { error, execute:apiExecute, isLoading } = useFetchWithMsal({
      scopes: [...scopes.read]
    });
    const execute = async () => {
      return await apiExecute("GET", endpoint(), null, true);
    }
    return {
      isLoading,
      error,
      execute:useCallback(execute, [apiExecute])
    }
  }
    
  export function useGetFundIndicatorsApi() {
    const {scopes, endpoint} = protectedResources.fundIndicatorsApi;
    const { error, execute:apiExecute, isLoading } = useFetchWithMsal({
      scopes: [...scopes.read]
    });
    const execute = async (fundCode, indicatorId, ) => {
      if(!fundCode) throw new Error("Fund code is required");
      return await apiExecute("GET", endpoint(fundCode, indicatorId), null, true);
    }
    return {
      isLoading,
      error,
      execute:useCallback(execute, [apiExecute])
    }
  }

  export function usePutFundIndicatorsApi() {
    const {scopes, endpoint} = protectedResources.fundIndicatorsApi;
    const { error, execute:apiExecute, isLoading } = useFetchWithMsal({
      scopes: [...scopes.write]
    });
    const execute = async (fundIndicator) => {
      if(!fundIndicator) throw new Error("Fund indicator is required");
      return await apiExecute("PUT", endpoint(fundIndicator.fundCode, fundIndicator.indicatorId), fundIndicator, true);
    }
    return {
      isLoading,
      error,
      execute:useCallback(execute, [apiExecute])
    }
  }  
  
  export function useDeleteFundIndicatorApi() {
    const {scopes, endpoint} = protectedResources.fundIndicatorsApi;
    const { error, execute:apiExecute, isLoading } = useFetchWithMsal({
      scopes: [...scopes.write]
    });
    const execute = async (fundCode, indicatorId) => {
      if(!fundCode) throw new Error("Fund code is required");
      if(!indicatorId) throw new Error("Indicator id is required");
      return await apiExecute("DELETE", endpoint(fundCode, indicatorId), null, true);
    }
    return {
      isLoading,
      error,
      execute:useCallback(execute, [apiExecute])
    }
  }    
  
  export function useGetIndicatorsApi() {
    const {scopes, endpoint} = protectedResources.indicatorsApi;
    const { error, execute:apiExecute, isLoading } = useFetchWithMsal({
      scopes: [...scopes.read]
    });
    const execute = async (indicatorId) => {
      return await apiExecute("GET", endpoint(indicatorId), null, true);
    }
    return {
      isLoading,
      error,
      execute:useCallback(execute, [apiExecute])
    }
  }