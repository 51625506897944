import React from 'react';
import { FormControl, Select, MenuItem, FormHelperText } from '@mui/material';

function DropdownField(props) {
  const {  value, options, defaultValue, onChange, errorText } = props;

  return (
    <FormControl fullWidth error={Boolean(errorText)}>
      <Select value={value}  defaultValue={defaultValue} onChange={onChange} color={errorText ? 'error' : 'primary'}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  );
}

export default DropdownField;