import React, { useState, useEffect }  from 'react';
import { useMsal } from "@azure/msal-react";
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@mui/material';
import { addMetricToIndicator } from '../services/api';
import errorMessageToDisplay from '../services/error';
import DropdownField from './DropdownField';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddMetricToIndicator = React.memo((props) => {
    const { setAddMetric, setIsLoading, setErrorMessage, indicator, setIndicatorMetrics, indicatorMetrics, metrics } = props;
    const { instance } = useMsal();
    const [selectedMetricId, setSelectedMetricId] = useState(0);
    const [open, setOpen] = React.useState(true);
    const [metricError, setMetricError] = useState('');
    const errorText = 'Field is required';

    function mapMetricNameOptions(metrics) {
        return metrics.map((metric) => ({
          label: metric.name,
          value: metric.id,
        }));
    }

    const filterMetricsAlreadyLinked = () => {
      var linkedMetricIds = indicatorMetrics.map(metric => metric.id);
      return metrics.filter(metric => !linkedMetricIds.includes(metric.id));
    }

    const handleFieldChange = (event, field) => {
      setMetricError('');
      setSelectedMetricId(event.target.value);
    }

    const constructIndicatorMetric = () => {
      return {id: selectedMetricId, lastUpdatedBy: instance.getActiveAccount().username};
    }

    const fieldsAreValid = () => {
      var fieldsAreValid = true;
      if (selectedMetricId === 0) {
        setMetricError(errorText);
        fieldsAreValid = false;
      }
      return fieldsAreValid;
    }

    const handleSave = () => {
      console.log(indicator);
      if(!fieldsAreValid()) {
        return;
      }
      setIsLoading(true);
      addMetricToIndicator(indicator.id, constructIndicatorMetric())
      .then((response) => {
        var linkedMetric = metrics.find(metric => metric.id === selectedMetricId);
        setIndicatorMetrics([...indicatorMetrics, linkedMetric]);
        setIsLoading(false);
        setAddMetric(false);
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e)
        setErrorMessage(errorMessageToDisplay);
      })                   
    }
  
    const handleClose = () => {
      setAddMetric(false);
    }

    return (
        <>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
            maxWidth="lg"
          >
            <DialogTitle>{"Add Metric to Indicator"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3}><label><b>Metric:</b></label></Grid>
                  <Grid item xs={12} sm={9}><DropdownField options={mapMetricNameOptions(filterMetricsAlreadyLinked())} errorText={metricError} onChange={(event) => handleFieldChange(event, 'metric')} /></Grid>                  
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>                    
          </Dialog>
        </>
      );

});

export default AddMetricToIndicator;