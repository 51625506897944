// This file should be deleted once apis are moved to the newer 'useApi' way of doing things
import {  indicatorsRequest, 
          apiAccessToken, 
          metricsRequest, 
          indicatorDetailsRequest, 
          metricDetailsRequest, 
          metricValueUnitTypesRequest, 
          metricSourceFieldsRequest,
          metricByNameRequest,
          indicatorsByNameTypeAndRegRequest,
          indicatorGroupsRequest,
          metricToindicatorRequest
          } from './authConfig'


export async function getIndicators() {
  let request = await indicatorsRequest();
  return await doRequest(request);
}

export async function getIndicatorDetails(indicatorId) {
  let request = await indicatorDetailsRequest(indicatorId);
  return await doRequest(request);
}

export async function getIndicatorByNameTypeAndReg(name, type, reg) {  
  let request = await indicatorsByNameTypeAndRegRequest(name, type, reg);     
  const token = await apiAccessToken()

  const headers = new Headers()
  const bearer = `Bearer ${token}`

  headers.append('Authorization', bearer)

  const options = {
    method: 'GET',
    headers: headers,
  }
  
  return await fetch(request.url, options)
    .then(      
      (response) => {
        if(!response.ok && response.status === 404) {
          return {indicatorExists: false};
        }
        if (!response.ok || response.status === 400) {
          throw new Error(JSON.stringify({
              status: response.status,
              body: response.text()
          }))
        }
        return response.json();
      }
    )
    .catch(error => {
      console.log(error)
      throw new Error(error)
    })
}


export async function addIndicator(indicator, user) {
  let request = await indicatorsRequest();
  return await addEntity(request, indicator, user);
}

export async function addMetricToIndicator(indicatorId, metric) {
  let request = await metricToindicatorRequest(indicatorId);
  return await addEntity(request, metric, null);
}

export async function removeMetricFromIndicator(indicatorId, metric) {
  let request = await metricToindicatorRequest(indicatorId);
  return await removeEntityRelationship(request, metric);
}

export async function removeEntityRelationship(request, entity) {
  const token = await apiAccessToken()

  const headers = new Headers()
  const bearer = `Bearer ${token}`

  headers.append('Authorization', bearer)
  headers.append('Content-Type', 'application/json')

  var body = {...entity}

  const options = {
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify(body)
  }
  
  return await fetch(request.url, options)
    .then(      
      (response) => {
        if (!response.ok || response.status === 400) {
          throw new Error(JSON.stringify({
              status: response.status,
              body: response.text()
          }))
        }
      }
    )
    .catch(error => {
      console.log(error)
      throw new Error(error)
    })
}

export async function updateIndicator(indicator, user) {
  let request = await indicatorDetailsRequest(indicator.id);  
  const token = await apiAccessToken()

  const headers = new Headers()
  const bearer = `Bearer ${token}`

  headers.append('Authorization', bearer)
  headers.append('Content-Type', 'application/json')

  var body = {
    ...indicator,
    "lastUpdatedBy": user
  }

  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(body)
  }
  
  return await fetch(request.url, options)
    .then(      
      (response) => {
        if (!response.ok || response.status === 400) {
          throw new Error(JSON.stringify({
              status: response.status,
              body: response.text()
          }))
        }
      }
    )
    .catch(error => {
      console.log(error)
      throw new Error(error)
    })
}

export async function getIndicatorGroups() {  
  let request = await indicatorGroupsRequest();     
  return await doRequest(request);
}

export async function getMetrics() {  
  let request = await metricsRequest();     
  return await doRequest(request);
}

export async function getMetricDetails(metricId) {  
  let request = await metricDetailsRequest(metricId);     
  return await doRequest(request);
}

export async function getMetricByName(metricName) {  
  let request = await metricByNameRequest(metricName);     
  const token = await apiAccessToken()

  const headers = new Headers()
  const bearer = `Bearer ${token}`

  headers.append('Authorization', bearer)

  const options = {
    method: 'GET',
    headers: headers,
  }
  
  return await fetch(request.url, options)
    .then(      
      (response) => {
        if(!response.ok && response.status === 404) {
          return {metricExists: false};
        }
        if (!response.ok || response.status === 400) {
          throw new Error(JSON.stringify({
              status: response.status,
              body: response.text()
          }))
        }
        return {metricExists: true};
      }
    )
    .catch(error => {
      console.log(error)
      throw new Error(error)
    })
}

export async function getMetricValueUnitTypes() {  
  let request = await metricValueUnitTypesRequest();     
  return await doRequest(request);
}

export async function getMetricSourceFields() {  
  let request = await metricSourceFieldsRequest();     
  return await doRequest(request);
}

export async function addMetric(metric, user) {
  let request = await metricsRequest();
  return await addEntity(request, metric, user);
}

export async function updateMetric(metric, user) {
  let request = await metricDetailsRequest(metric.id);  
  const token = await apiAccessToken()

  const headers = new Headers()
  const bearer = `Bearer ${token}`

  headers.append('Authorization', bearer)
  headers.append('Content-Type', 'application/json')

  var body = {
    ...metric,
    "lastUpdatedBy": user
  }

  const options = {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(body)
  }
  
  return await fetch(request.url, options)
    .then(      
      (response) => {
        if (!response.ok || response.status === 400) {
          throw new Error(JSON.stringify({
              status: response.status,
              body: response.text()
          }))
        }
      }
    )
    .catch(error => {
      console.log(error)
      throw new Error(error)
    })
}

export async function addEntity(request, entity, user, httpMethod = 'POST') {
  const token = await apiAccessToken()

  const headers = new Headers()
  const bearer = `Bearer ${token}`

  headers.append('Authorization', bearer)
  headers.append('Content-Type', 'application/json')

  var body = user != null ? 
              {
                ...entity,
                "lastUpdatedBy": user
              } 
            : 
              {...entity}

  const options = {
    method: httpMethod,
    headers: headers,
    body: JSON.stringify(body)
  }
  
  return await fetch(request.url, options)
    .then(      
      (response) => {
        if (!response.ok || response.status === 400) {
          throw new Error(JSON.stringify({
              status: response.status,
              body: response.text()
          }))
        }
      }
    )
    .catch(error => {
      console.log(error)
      throw new Error(error)
    })
}

export async function doRequest(request) {
  const token = await apiAccessToken()

  const headers = new Headers()
  const bearer = `Bearer ${token}`

  headers.append('Authorization', bearer)

  const options = {
    method: 'GET',
    headers: headers,
  }
  
  return await fetch(request.url, options)
    .then(      
      (response) => {
        if (!response.ok || response.status === 400) {
          throw new Error(JSON.stringify({
              status: response.status,
              body: response.text()
          }))
        }
        return response.json()
      }
    )
    .catch(error => {
      console.log(error)
      throw new Error(error)
    })
}