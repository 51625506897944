let settings;

export const getSettings = async () => {
    if(settings == null){
        const settingsFileAddress = process.env.NODE_ENV === "development" 
            ? "/settings/settings.local.json" 
            : "/settings/settings.json"
            console.log(settingsFileAddress);
        const settingsResponse = await fetch(settingsFileAddress)
        const settingsObject = await settingsResponse.json()    
        settings = settingsObject
    }

    return settings
}