import React, { useEffect, useState } from 'react';
import { getIndicators, getIndicatorGroups } from '../services/api';
import Box from '@mui/material/Box';
import DataTable from './DataTable';
import { textSearch, dataForColumn } from '../services/tableUtils';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import CustomTablePagination from './CustomTablePagination';
import { Alert, AlertTitle } from '@mui/material'
import errorMessageToDisplay from '../services/error';
import { useNavigate, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import AddIndicator from './AddIndicator';
import { AuditInfoTooltip } from './AuditInfoTooltip';

const Indicator = (props) => { 
  const { user } = props;

  const [indicators, setIndicators] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [add, setAdd] = React.useState(false);
  const [indicatorGroups, setIndicatorGroups] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const getIndicatorsList = async () => {
    getIndicators()
    .then((response) => {
      setIndicators([...response]);
      getIndicatorGroups()
      .then((response) => {
        setIndicatorGroups([...response]);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(errorMessageToDisplay);
      });      
    })
    .catch((e) => {
      setIsLoading(false);
      setErrorMessage(errorMessageToDisplay);
    });  
  }

  useEffect(() => {
    getIndicatorsList()
  }, []);

  const navigateToIndicatorDetail = (event, indicator) => {
    navigate(`${location.pathname}\\${indicator.id}`);
  };

  const addNewIndicator = () => {
    setAdd(true);
  }

  const IndicatorsTable = ({data, setData }) => {
    const [dataCount, setDataCount] = useState(data.length);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);

    const [columnSettings, setColumnSettings] = useState(
    {
        settings:{
            defaultSortColumn: 'name',
            hasGroup: false
        },
        cells:[
            { id: 'auditInfo', type: "string", disablePadding: false, label: '', style: { width: "5%" }, 
                sort: {enable: false}, render: (row, data, index) => <AuditInfoTooltip row={row}/>},
            { id: 'indicatorGroupName', type: "string", disablePadding: false, label: 'Indicator Group', style: { width: "10%" }, filter: {getColumnData: dataForColumn, comparator: textSearch, value:null}},
            { id: 'type', type: "string", disablePadding: false, label: 'Indicator Type', style: { width: "10%" }, filter: {getColumnData: dataForColumn, comparator: textSearch, value:null}},
            { id: 'name', type: "string", disablePadding: false, label: 'Indicator Name', style: { width: "75%" }, filter: {getColumnData: dataForColumn, comparator: textSearch, value:null}}
        ]
    });

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }; 
  
    return (
        <Box sx={{width: "100%"}}>
            <DataTable
                id="IndicatorsTable"
                data={data}
                setData={setData}
                setFilterCount={setDataCount}
                page={page}
                rowsPerPage={rowsPerPage}
                columnSettings={columnSettings}
                setColumnSettings={setColumnSettings}
                onRowClick={navigateToIndicatorDetail}
                />   
            <br/>
            <div style={{float: 'right'}}>
              <CustomTablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  slotProps={{
                    select: {
                      'aria-label': 'rows per page',
                    },
                    actions: {
                      showFirstButton: true,
                      showLastButton: true,
                    },
                  }}
                  count={dataCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />   
            </div> 
            <br/>  
            {
              user.hasMaintainerRole === true 
              ? 
                <div style={{float: 'left'}}>
                  <Button variant="outlined" onClick={addNewIndicator}>Add</Button>
                </div> 
              : <></>
            }     
            {
              add === true ?
                <AddIndicator setAdd={setAdd} setIsLoading={setIsLoading} setErrorMessage={setErrorMessage} indicatorGroupsAndTypes={indicatorGroups} setIndicators={setIndicators}/>
              : <></>
            }         
        </Box>
    );
  }
  

  return (
    <>      
      <br/>
      {isLoading === true 
        ? 
          <Backdrop open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        : 
          errorMessage !== "" 
          ? <Alert severity="error">
              <AlertTitle>Error</AlertTitle>  
              {errorMessage}
            </Alert> 
          :    
          <div className="App">
            <IndicatorsTable
              id="IndicatorsTable"
              data={indicators}
              setData={setIndicators}
            />
          </div>
        }
    </>
  )
};

export const Indicators = (props) => {    
    const { user } = props; 
    return (
      <>
          <Indicator user={user}/>
      </>
    )
};