import React from 'react';
import { Grid } from '@mui/material';

const MetricDetailGridItem = React.memo((props) => {

    const { label, value } = props;
  
    return (
      <>
        <Grid item xs={12} sm={3}>
          <label><b>{label}</b></label>
        </Grid>
        <Grid item xs={12} sm={9}>
          <span>{value}</span>
        </Grid>
      </>
    );
  });

export default MetricDetailGridItem;