import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { Alert, AlertTitle } from '@mui/material'
import errorMessageToDisplay from '../../services/error';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../../services/authConfig';
import { FundIndicators } from './components/FundIndicators';
import {useGetFundsApi} from '../../hooks/useApis'

const FundPageContent = (props) => { 
  const {user} = (props);
  const { execute:getFunds } = useGetFundsApi();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fundsList, setFundsList] = useState(null);

  const getFundsList = async () => {
    try{
        let response = await getFunds();
        setFundsList(response);
        setIsLoading(false);
      } catch(e) {
        setIsLoading(false);
        setErrorMessage(errorMessageToDisplay);
      }  
  }

  useEffect(() => {
    getFundsList();    
  }, [getFunds, isLoading]);
 

  return(
  <>
     {isLoading === true 
      ? 
        <Backdrop open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      : 
        errorMessage 
        ? <Alert severity="error">
            <AlertTitle>Error</AlertTitle>  
            {errorMessage}
          </Alert> 
        :
        <Box sx={{width: "100%"}}>
          {fundsList ?
          <FundView fundsList={fundsList} user={user}/>
          : <></>
          }
        </Box>
    }
  </>
  )
}

const FundView = (props) => {
  const {fundsList, user} = (props);
  const [selectedFund, setSelectedFund] = useState(null);

  return (
    <> 
            <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={1}></Grid>
              <Grid item xs={4}>
                <Autocomplete
                  id="fundList"
                  sx={{ width: 400 }}
                  options={fundsList}
                  value={selectedFund || null}
                  autoHighlight
                  getOptionLabel={(option) => option.code}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.label} ({option.name} )
                    </Box>
                  )}
                  onChange={(event, newValue) => {
                    console.log("Chosen fund: " + newValue?.code);
                    setSelectedFund(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a fund"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                      }}
                    />
                  )}
                />
                </Grid>
                <Grid item xs={4}>
                      {!(!selectedFund) ? 
                          <>
                            <b>Fund Code:</b> {selectedFund.code} 
                            <br/> 
                            <b>Fund Name:</b> {selectedFund.name} 
                            <br/>
                            <b>SFDR Classification: </b> {selectedFund.sfdrClassification}
                          </>
                          : <>
                              <br/>
                              Please select a fund to begin
                            </>
                        }
                </Grid>
              </Grid>
              </Box>
            <br/>
            <FundIndicators fund={selectedFund} id="FundIndicatorsTable" user={user}/>
          </>
  )
}

export const FundPage = (props) => {
    const authRequest = {
      ...loginRequest
    };

    const { user } = props;     
    return (
      <MsalAuthenticationTemplate 
          interactionType={InteractionType.Redirect} 
          authenticationRequest={authRequest}
      >
          <FundPageContent user={user}/>
      </MsalAuthenticationTemplate>
    )
};
  