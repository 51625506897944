import React, { useEffect, useState } from 'react';
import { getIndicatorDetails, getIndicatorGroups, getMetrics, removeMetricFromIndicator } from '../services/api';
import { useMsal } from "@azure/msal-react";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import DataTable from './DataTable';
import { textSearch, dataForColumn } from '../services/tableUtils';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import CustomTablePagination from './CustomTablePagination';
import { Alert, AlertTitle } from '@mui/material'
import errorMessageToDisplay from '../services/error';
import {useParams} from 'react-router-dom';
import Button from '@mui/material/Button';
import EditIndicator from './EditIndicator';
import AddMetricToIndicator from './AddMetricToIndicator';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { AuditInfoTooltip } from './AuditInfoTooltip';

const Detail = (props) => { 
  const { user } = props;

  const [indicatorMetrics, setIndicatorMetrics] = useState([]);
  const [indicator, setIndicator] = useState({});
  const [indicatorGroups, setIndicatorGroups] = useState([]);
  const [linkedFunds, setLinkedFunds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [edit, setEdit] = React.useState(false);
  const [addMetric, setAddMetric] = React.useState(false);
  const [metrics, setMetrics] = useState([{}]);
  const [confirmMetricRemoval, setConfirmMetricRemoval] = React.useState(false);
  const [selectedMetric, setSelectedMetric] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const params = useParams();
  const { instance } = useMsal();

  const populateIndicatorDetails = async (indicatorId) => {
    getIndicatorDetails(indicatorId)
    .then((response) => {
      setIndicator(response);
      if(response.metrics != null){
        setIndicatorMetrics([...response.metrics]);
      }
      if(response.funds != null) {
        setLinkedFunds([...response.funds]);
      }
      getIndicatorGroups()
      .then((response) => {
        setIndicatorGroups([...response]);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(errorMessageToDisplay);
      }); 
    })
    .catch((e) => {
      setIsLoading(false);
      console.log(e)
      setErrorMessage(errorMessageToDisplay);
    })  
  }

  useEffect(() => {
    populateIndicatorDetails(params.id)
  }, []);

  const removeMetricFromLikedMetricsList = (metricId) => {
    let updatedMetrics = [...indicatorMetrics];
    updatedMetrics = updatedMetrics.filter((metric) => metric.id !== metricId);
    setIndicatorMetrics(updatedMetrics);
  }
  
  const removeIndicatorMetricRelationsip = (event, row) => {
    setConfirmMetricRemoval(true);
    setSelectedMetric(row)
  }

  const handleConfirmationClose = () => {
    setConfirmMetricRemoval(false);
    setSelectedMetric(null);
  };

  const constructIndicatorMetric = () => {
    return {id: selectedMetric.id, lastUpdatedBy: instance.getActiveAccount().username};
  }

  const handleConfirmation = () => {
    setIsLoading(true);
    removeMetricFromIndicator(indicator.id, constructIndicatorMetric())
    .then((response) => {
      removeMetricFromLikedMetricsList(selectedMetric.id);
      setIsLoading(false);
      setConfirmMetricRemoval(false);
      setSelectedMetric(null);
    })
    .catch((e) => {
      setIsLoading(false);
      setErrorMessage(errorMessageToDisplay);
    }); 
  };

  const ConfirmationDialog = ({ open, onClose, onConfirm, metricName }) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent style={{textAlign: 'justify'}}>
          Are you sure you want to remove link to metric <br/>   '{metricName}'?
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            No
          </Button>
          <Button onClick={onConfirm} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  const IndicatorMetricsTable = ({data, setData }) => {

    const [dataCount, setDataCount] = useState(data.length);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [columnSettings, setColumnSettings] = useState(
    {
        settings:{
            defaultSortColumn: 'name',
            hasGroup: false
        },
        cells:[
            { id: 'auditInfo', type: "string", disablePadding: false, label: '', style: { width: "3%" }, 
                sort: {enable: false}, render: (row, data, index) => <AuditInfoTooltip row={row}/>},
            { id: 'name', type: "string", disablePadding: false, label: 'Name', style: { width: "41%" }, filter: {getColumnData: dataForColumn, comparator: textSearch, value:null}},
            { id: 'displayName', type: "string", disablePadding: false, label: 'Display Name', style: { width: "41%" }, filter: {getColumnData: dataForColumn, comparator: textSearch, value:null} },
            { id: 'valueUnitType', type: "string", disablePadding: false, label: 'Unit Value Type', style: { width: "10%" }, filter: {getColumnData: dataForColumn, comparator: textSearch, value:null} },
            { id: 'remove', type: "string", disablePadding: false, label: '', style: { width: "5%" }, 
                sort: {enable: false},
                render: (row, data, index) =>
                  user.hasMaintainerRole === true ?
                    <Button 
                        key={row.type + "button-view"}
                        onClick={(event) => removeIndicatorMetricRelationsip(event, row)}
                        size="small"
                    >
                        Remove
                    </Button>
                  : null
            }
        ]
    });

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }; 
    
    return (
      <>       
          <Box sx={{width: "100%"}}>
            <DataTable
                id="IndicatorMetricsTable"
                data={data}
                setData={setData}
                setFilterCount={setDataCount}
                page={page}
                rowsPerPage={rowsPerPage}
                columnSettings={columnSettings}
                setColumnSettings={setColumnSettings}
                />         
            <br/>
            {
              dataCount > 5 
              ?
                <div style={{float: 'right'}}>
                  <CustomTablePagination
                      rowsPerPageOptions={[5, 10]}
                      colSpan={3}
                      slotProps={{
                        select: {
                          'aria-label': 'rows per page',
                        },
                        actions: {
                          showFirstButton: true,
                          showLastButton: true,
                        },
                      }}
                      count={dataCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      />   
                </div> 
              :
              <></>
            }
            
          </Box>
      </>
        
    );
  }

  const IndicatorLinkedFundsTable = ({data, setData }) => {

    const [dataCount, setDataCount] = useState(data.length);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [columnSettings, setColumnSettings] = useState(
    {
        settings:{
            defaultSortColumn: 'code',
            hasGroup: false
        },
        cells:[
            { id: 'code', type: "string", disablePadding: false, label: 'Fund Code', style: { width: "20%" }, filter: {getColumnData: dataForColumn, comparator: textSearch, value:null}},
            { id: 'name', type: "string", disablePadding: false, label: 'Fund Name', style: { width: "60%" }, filter: {getColumnData: dataForColumn, comparator: textSearch, value:null} },
            { id: 'sfdrClassification', type: "string", disablePadding: false, label: 'Article Clasification', style: { width: "20%" }, filter: {getColumnData: dataForColumn, comparator: textSearch, value:null} }
        ]
    });

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }; 
    
    return (
      <>       
          <Box sx={{width: "100%"}}>
            <DataTable
                id="IndicatorLinkedFundsTable"
                data={data}
                setData={setData}
                setFilterCount={setDataCount}
                page={page}
                rowsPerPage={rowsPerPage}
                columnSettings={columnSettings}
                setColumnSettings={setColumnSettings}
                />         
            <br/>
            {
              dataCount > 5 
              ?
                <div style={{float: 'right'}}>
                  <CustomTablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      colSpan={3}
                      slotProps={{
                        select: {
                          'aria-label': 'rows per page',
                        },
                        actions: {
                          showFirstButton: true,
                          showLastButton: true,
                        },
                      }}
                      count={dataCount}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      />   
                </div> 
              :
              <></>
            }
            
          </Box>
      </>        
    );
  }

  const editIndicator = () => {
    setEdit(true);
  }

  const addMetricToIndicator = () => {
    setIsLoading(true);
    getMetrics()
    .then((response) => {
      setMetrics([...response]);
      setIsLoading(false);
      setAddMetric(true);
    })
    .catch((e) => {
      setIsLoading(false);
      setErrorMessage(errorMessageToDisplay);
    });
  }

  return (
    <>      
      <br/>
      {isLoading === true 
        ? 
          <Backdrop open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        : 
          errorMessage !== "" 
          ? <Alert severity="error">
              <AlertTitle>Error</AlertTitle>  
              {errorMessage}
            </Alert> 
          :    
          <>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={1}>      
                <Grid item sm={1.5}><b>Indicator Group:</b></Grid>
                <Grid item sm={4}>{indicator.indicatorGroupName}</Grid>
              </Grid>
              <Grid container spacing={1}>      
                <Grid item sm={1.5}><b>Indicator Name:</b></Grid>
                <Grid item sm={4}>{indicator.name}</Grid>
              </Grid>
              <Grid container spacing={1}>      
                <Grid item sm={1.5}><b>Indicator Type:</b></Grid>
                <Grid item sm={4}>{indicator.type}</Grid>
              </Grid>
              <Grid container spacing={1}>      
                <Grid item sm={1.5}><b>Optional  for reporting:</b></Grid>
                <Grid item sm={4}>{indicator.isOptional ? 'Y' : 'N'}</Grid>
              </Grid>
              <Grid container spacing={1}>  
                <Grid item sm="12" alignContent={'flex-end'}>
                  {
                    user.hasMaintainerRole === true 
                    ? 
                      <>
                        <div style={{float: 'right'}}>
                          <Button variant="outlined" onClick={editIndicator}>Edit</Button>
                        </div> 
                        <br/> 
                        <br/>
                      </>
                    : <></>
                  }
                </Grid>
              </Grid>
              <Grid container spacing={1}>  
                  <Grid item sm={2}><br/><b>Associated Metrics</b></Grid>                  
              </Grid>
            </Box>
            <IndicatorMetricsTable
              id="IndicatorMetricsTable"
              data={indicatorMetrics}
              setData={setIndicatorMetrics}
            />
            
            <Box sx={{ flexGrow: 1 }}>
                <Grid item xs={1}></Grid>          
                  <Grid item xs={4}>
                        {
                          <>
                            {
                              user.hasMaintainerRole === true 
                              ? 
                                <>
                                  <div style={{float: 'right'}}>
                                    <Button variant="outlined" onClick={addMetricToIndicator}>Add</Button>
                                  </div> 
                                  <br/> 
                                  <br/>
                                </>
                              : <></>
                            }
                            <br/> 
                            <b>Linked Funds</b> 
                          </>
                        }
                  </Grid>
            </Box>
            <IndicatorLinkedFundsTable
              id="IndicatorLinkedFundsTable"
              data={linkedFunds}
              setData={setLinkedFunds}
            />
            {
              edit === true ?
                <EditIndicator setEdit={setEdit} setIsLoading={setIsLoading} setErrorMessage={setErrorMessage} indicatorGroupsAndTypes={indicatorGroups} indicator={indicator} setIndicator={setIndicator}/>
              : <></>
            }  
            {
              addMetric === true ?
                <AddMetricToIndicator setAddMetric={setAddMetric} setIsLoading={setIsLoading} setErrorMessage={setErrorMessage} indicator={indicator} setIndicatorMetrics={setIndicatorMetrics} indicatorMetrics={indicatorMetrics} metrics={metrics}/>
              : <></>
            } 
            {
              confirmMetricRemoval === true ?
                <ConfirmationDialog open={true} onClose={handleConfirmationClose} onConfirm={handleConfirmation} metricName={selectedMetric.name}/>
              : <></>
            }
          </>
          
      }
    </>
  )
};

export const IndicatorDetail = (props) => {    
  const { user } = props; 
  return (
    <>
        <Detail user={user}/>
    </>
  )
};