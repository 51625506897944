import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';


const AuditInfo = ({ row }) => {

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))({
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 450,
        },
      });

    const renderTooltipTitle = (row) => {
        return  (
          <Typography>
            <Grid container spacing={1}>
                <Grid item sm={5}>First Created Date:</Grid>
                <Grid item sm={6}>{row.createdDate}</Grid>     
                <Grid item sm={5}>Last Updated Date:</Grid>
                <Grid item sm={6}>{row.lastUpdatedDate}</Grid>    
                <Grid item sm={5}>Last Updated User:</Grid>
                <Grid item sm={6}>{row.lastUpdatedBy}</Grid>       
            </Grid>
          </Typography>
        );
    };
    return  <CustomWidthTooltip title={renderTooltipTitle(row)} arrow>
                <InfoOutlinedIcon fontSize="small"/>
            </CustomWidthTooltip>
}

export const AuditInfoTooltip = (props) => { 
    const { row } = props; 
    return (
      <>
          <AuditInfo row={row}/>
      </>
    )
  };