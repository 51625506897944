import React, { useState }  from 'react';
import { useMsal } from "@azure/msal-react";
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@mui/material';
import { usePutFundIndicatorsApi, useGetFundIndicatorsApi } from '../../../hooks/useApis';
import errorMessageToDisplay from '../../../services/error';
import DateSelector from '../../../components/DateSelector'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddIndicatorToFund = React.memo((props) => {
    const { setAddFundIndicator, setIsLoading, setErrorMessage, fund, setFundIndicators, fundIndicators, indicators } = props;
    const { instance } = useMsal();
    const [selectedIndicator, setSelectedIndicator] = useState(null);
    const [validFromDate, setValidFromDate] = useState(null);
    const [validToDate, setValidToDate] = useState(null);
    const [open, setOpen] = React.useState(true);
    const [indicatorError, setIndicatorError] = useState('');
    const [validFromError, setValidFromError] = useState('');
    const { execute:getFundIndicators } = useGetFundIndicatorsApi();
    const { execute:putFundIndicators } = usePutFundIndicatorsApi();


    const filterindicatorsAlreadyLinked = () => {
      var linkedIndicatorIds = fundIndicators.map(fundIndicator => fundIndicator["_embedded"].indicator.id);
      return indicators.filter(indicator => !linkedIndicatorIds.includes(indicator.id));
    }

    const sortedIndicatorNameOptions = () => {
      function compareIndicators(a, b) {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      }
      let options = filterindicatorsAlreadyLinked().sort(compareIndicators);
      return options;
    };

    const handleIndicatorChange = (event, field) => {
      setIndicatorError('');
      setSelectedIndicator(field);
    }

    const constructFundIndicator = () => {
      return {fundCode: fund.code, indicatorId: selectedIndicator.id, lastUpdatedBy: instance.getActiveAccount().username, validFromDate: validFromDate, validToDate: validToDate};
    }

    const fieldsAreValid = () => {
      var fieldsAreValid = true;
      setIndicatorError(null);
      setValidFromError(null);
      if (!selectedIndicator) {
        setIndicatorError("Indicator is required");
        fieldsAreValid = false;
      }
      if (!validFromDate) {
        setValidFromError("Valid From Date is required");
        fieldsAreValid = false;
      }
      if (validToDate && validFromDate > validToDate) {
        setValidFromError("Valid From Date must be on or before Valid To Date");
        fieldsAreValid = false;
      }      
      return fieldsAreValid;
    }

    const handleSave = () => {
      if(!fieldsAreValid()) {
        return;
      }
      setIsLoading(true);
      putFundIndicators(constructFundIndicator())
      .then((response) => {
        getFundIndicators(fund.code, selectedIndicator.id).then((response) => {
          let newFundIndicator = response;
          console.log("newFundIndicator: ", newFundIndicator);
          fundIndicators.push(newFundIndicator);
          setFundIndicators([...fundIndicators]);
          setIsLoading(false);
          setAddFundIndicator(false);
        })
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e)
        setErrorMessage(errorMessageToDisplay);
      })                   
    }
  
    const handleClose = () => {
      setAddFundIndicator(false);
      setOpen(false);
    }


    const updateValidFromDate =  (date) => {
      // N.B. if I don't do this, then the date uses timezone info and is a day out at certain times of year
      if(date){
        let stringDate = dayjs(date).format('YYYY-MM-DD'); 
        setValidFromDate(stringDate);
      } else {
        setValidFromDate(null);
      }
      
      setValidFromError('');
    };

    const updateValidToDate = (date) => {
      // N.B. if I don't do this, then the date uses timezone info and is a day out at certain times of year
      if(date){
        let stringDate = dayjs(date).format('YYYY-MM-DD'); 
        setValidToDate(stringDate);
      } else {
        setValidToDate(null);
      }
      setValidFromError('');
    };    
    
    return (
        <>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
            maxWidth="lg"
          >
            <DialogTitle>{"Add Fund Indicator"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3}><label><b>Indicator:</b></label></Grid>
                  <Grid item xs={12} sm={9}>
                      <Autocomplete
                        id="indicatorList"
                        sx={{ width: 400 }}
                        options={sortedIndicatorNameOptions()}
                        value={selectedIndicator || null}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                          handleIndicatorChange(event, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Choose an indicator"
                            inputProps={{
                              ...params.inputProps
                            }}
                            error={indicatorError}
                          />
                        )}
                      />                    
                  </Grid>                  

                  <Grid item xs={12} sm={3}><label><b>Valid From Date:</b></label></Grid>
                  <Grid item xs={12} sm={9}><DateSelector date={validFromDate} sx={{width:10}} setDate={updateValidFromDate} label="Valid From" errorText={validFromError} required={true}/></Grid>                  

                  <Grid item xs={12} sm={3}><label><b>Valid To Date (Optional):</b></label></Grid>
                  <Grid item xs={12} sm={9}><DateSelector date={validToDate} sx={{width:10}} setDate={updateValidToDate} label="Valid To (Optional)"/></Grid>                  
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>                    
          </Dialog>
        </>
      );

});

export default AddIndicatorToFund;