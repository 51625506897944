import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import DataTable from '../../../components/DataTable';
import { textSearch, dataForColumn } from '../../../services/tableUtils';
import CustomTablePagination from '../../../components/CustomTablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { Alert, AlertTitle } from '@mui/material'
import errorMessageToDisplay from '../../../services/error';
import { AuditInfoTooltip } from '../../../components/AuditInfoTooltip';
import Button from '@mui/material/Button';
import AddIndicatorToFund from './AddIndicatorToFund';
import EditFundIndicatorDialog from './EditFundIndicatorDialog';
import {useGetFundIndicatorsApi, useGetIndicatorsApi, useDeleteFundIndicatorApi} from '../../../hooks/useApis';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export const FundIndicators = ({fund, user}) => {
    // const { execute:getFundIndicators } = useGetFundIndicatorsApi();
    const { execute:getFundIndicators } = useGetFundIndicatorsApi();

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fundIndicators, setFundIndicators] = useState(null);

    const getFundIndicatorsList = async (fundCode, getFundIndicators) => {
        setIsLoading(true);
        getFundIndicators(fundCode)
        .then((response) => {
          setFundIndicators(response);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setErrorMessage(errorMessageToDisplay);
        })  
      }
    useEffect(() => {
        if(fund && getFundIndicators) getFundIndicatorsList(fund.code, getFundIndicators);
        else setFundIndicators(null);
    }, [fund, getFundIndicators]);  

  
    return(
    <>
       {isLoading === true 
        ? 
          <Backdrop open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        : 
          errorMessage
          ? <Alert severity="error">
              <AlertTitle>Error</AlertTitle>  
              {errorMessage}
            </Alert> 
          : 
          <Box sx={{width: "100%"}}>
            {fundIndicators ?
            <FundIndicatorsDataTable setIsLoading={setIsLoading} setErrorMessage={setErrorMessage} user={user} selectedFund={fund} data={fundIndicators} setData={setFundIndicators}/>
            : <></>
            }
          </Box>
      }
    </>
    )
}

const FundIndicatorsDataTable = ({ setIsLoading, setErrorMessage, data, setData, user, selectedFund}) => {
    const [dataCount, setDataCount] = useState(data.length);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(data.length > 5 ? 25 : 5);
    const [confirmIndicatorRemoval, setConfirmIndicatorRemoval] = React.useState(false);
    const [fundIndicatorSelectedForRemoval, setFundIndicatorSelectedForRemoval] = useState(null);
    const [fundIndicatorSelectedForEdit, setFundIndicatorSelectedForEdit] = useState(null);
    const [addFundIndicator, setAddFundIndicator] = useState(false);
    const [editFundIndicatorDialogEnabled, setEditFundIndicatorDialogEnabled] = useState(false);
    const { execute:getIndicators } = useGetIndicatorsApi();
    const { execute:deleteFundIndicator } = useDeleteFundIndicatorApi();
    
    const [allIndicators, setAllIndicators] = useState([]);
    
    const [columnSettings, setColumnSettings] = useState(
    {
        settings:{
            defaultSortColumn: 'name',
            hasGroup: false
        },
        cells:[
            { id: 'auditInfo', type: "string", disablePadding: false, label: '', style: { width: "3%" }, 
                sort: {enable: false}, render: (row, data, index) => <AuditInfoTooltip row={row}/>},
            { id: 'indicatorGroup', type: "string", disablePadding: false, label: 'IndicatorGroup', style: { width: "9%" }, filter: {getColumnData: dataForColumn, comparator: textSearch, value:null}, getData: (row) => (row["_embedded"].indicator.indicatorGroupName)},
            { id: 'type', type: "string", disablePadding: false, label: 'Indicator Type', style: { width: "14%" }, filter: {getColumnData: dataForColumn, comparator: textSearch, value:null}, getData: (row) => (row["_embedded"].indicator.type) },
            { id: 'name', type: "string", disablePadding: false, label: 'Indicator Name', style: { width: "58%" }, filter: {getColumnData: dataForColumn, comparator: textSearch, value:null}, getData: (row) => (row["_embedded"].indicator.name) },
            { id: 'validFrom', type: "string", disablePadding: false, label: 'Apply From Date', style: { width: "7%" }, filter: {getColumnData: dataForColumn, comparator: textSearch, value:null}, getData: (row) => (row.validFromDate) },
            { id: 'validTo', type: "string", disablePadding: false, label: 'Apply Until Date', style: { width: "7%" }, filter: {getColumnData: dataForColumn, comparator: textSearch, value:null}, getData: (row) => (row.validToDate)},
            { id: 'actions', type: "string", disablePadding: false, label: '', style: { width: "5%" }, 
                sort: {enable: false},
                render: (row, data, index) =>
                user.hasMaintainerRole === true && !(!selectedFund) && data.length > 0 ?
                    <Box sx={{width:'100%'}} display="flex">
                      <Button 
                          key={row.indicatorId + "button-edit"}
                          onClick={(event) => editFundIndicatorRelationship(event, row)}
                          size="small"
                      >
                          Edit
                      </Button>                
                      <Button 
                          key={row.indicatorId + "button-remove"}
                          onClick={(event) => removeFundIndicatorRelationsip(event, row)}
                          size="small"
                      >
                          Remove
                      </Button>
                    </Box>
                    : <></>
            }          
        ]
    });

    const removeFundIndicatorRelationsip = (event, row) => {
        setConfirmIndicatorRemoval(true);
        setFundIndicatorSelectedForRemoval(row)
    }

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }; 

    const addFundIndicatorRelationship = () => {
      setIsLoading(false);
      getIndicators()
      .then((response) => {
        setAddFundIndicator(true);
        setAllIndicators([...response]);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(errorMessageToDisplay);
      })
    }
    
    const editFundIndicatorRelationship = (event, fundIndicator) => {
      setFundIndicatorSelectedForEdit(fundIndicator)
      setEditFundIndicatorDialogEnabled(true);
    }

    const closeEditFundIndicatorDialogCallback = () => {
      setEditFundIndicatorDialogEnabled(false);
    }

    const fundIndicatorUpdatedCallback = (fundIndicator) => {
      fundIndicator.lastUpdatedBy = 'mild';
    }

    const removeFundIndicatorFromFrontEnd = (indicatorId) => {
      let filteredFundIndicators = data.filter((fundIndicator) => fundIndicator["_embedded"].indicator.id !== indicatorId);
      setData([...filteredFundIndicators]);
    };

    const handleConfirmationClose = () => {
      setConfirmIndicatorRemoval(false);
      setFundIndicatorSelectedForRemoval(null);
    };

    const handleConfirmation = () => {
      setIsLoading(true);
      deleteFundIndicator(fundIndicatorSelectedForRemoval.fundCode, fundIndicatorSelectedForRemoval.indicatorId)
      .then((response) => {
        removeFundIndicatorFromFrontEnd(fundIndicatorSelectedForRemoval.indicatorId);
        setIsLoading(false);
        setConfirmIndicatorRemoval(false);
        setFundIndicatorSelectedForRemoval(null);
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(errorMessageToDisplay);
      }); 
    };

    return (
      // <>{!data ? <></> :
      <>
          <Box sx={{width: "100%"}}>
            <DataTable
                id="FundIndicatorsTable"
                data={data}
                setFilterCount={setDataCount}
                page={page}
                rowsPerPage={rowsPerPage}
                columnSettings={columnSettings}
                setColumnSettings={setColumnSettings}
                />         
            <br/>
            {
              dataCount > 5
              ?
                <CustomTablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    colSpan={3}
                    slotProps={{
                      select: {
                        'aria-label': 'rows per page',
                      },
                      actions: {
                        showFirstButton: true,
                        showLastButton: true,
                      },
                    }}
                    sx={{float: 'right'}}
                    count={dataCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    />                     
              :
              <></>              
            } 
            <>
            {
               confirmIndicatorRemoval === true ?
                 <ConfirmationDialog open={true} onClose={handleConfirmationClose} onConfirm={handleConfirmation} fundIndicator={fundIndicatorSelectedForRemoval}/>
               : <></>
             }
              <br/>
                {
                  user.hasMaintainerRole === true && !(!selectedFund)
                  ? 
                    addFundIndicator === true ?
                      <AddIndicatorToFund setAddFundIndicator={setAddFundIndicator} setIsLoading={setIsLoading} setErrorMessage={setErrorMessage} fund={selectedFund} setFundIndicators={setData} fundIndicators={data} indicators={allIndicators}/>
                    :                     
                    <div style={{float: 'left'}}>
                      <Button variant="outlined" onClick={addFundIndicatorRelationship}>Add</Button>
                    </div> 
                  : <></>
                }
                {
                  user.hasMaintainerRole === true && !(!selectedFund) && editFundIndicatorDialogEnabled === true && fundIndicatorSelectedForEdit ?
                    <EditFundIndicatorDialog closeDialogCallback={closeEditFundIndicatorDialogCallback} setIsLoading={setIsLoading} setErrorMessage={setErrorMessage} fund={selectedFund} fundIndicator={fundIndicatorSelectedForEdit} setFundIndicator={fundIndicatorUpdatedCallback}/>
                  : <></>
                }
              </>
          </Box>
       
      </>        
    );
  }
  const ConfirmationDialog = ({ open, onClose, onConfirm, fundIndicator }) => {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Confirm</DialogTitle>
        <DialogContent style={{textAlign: 'justify'}}>
          Are you sure you want to remove link to indicator <br/>   '{fundIndicator["_embedded"].indicator.name}'?
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            No
          </Button>
          <Button onClick={onConfirm} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    );
  };