import '../styles/App.css';

export const Home = ({user, displayFriendlyUserName}) => {
    return (
      <>
        <div className="App">    
          <h5 className="card-title">Welcome {displayFriendlyUserName}</h5>
          <br />
          <p>This is the ESG Metric Master and allows users to manage fund, applicable indicator and metrics relationships</p>
          {(!user || !user.roles || !(user.roles.includes("Support") || user.roles.includes("Maintenance")))
                    ?<p>You require a role of 'Support' or 'Maintenance' in order to use this application, but you do not have either role assigned. Please contact the <a href="mailto:Investment.Data.Platform.Support@mandg.com">Investment Data Platform Support (Investment.Data.Platform.Support@mandg.com)</a> for more guidence.</p>
           :null
          }
        </div>
      </>
    );
}