import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs';

const DateSelector = ({date, setDate, ...props}) => {
    // N.B. 'required' does not create validation errors, hnce using passed in errorText
    const {style, label, slotProps, sx, disabled, inputSx, errorText, minDate, maxDate, required} = props;

    let textBoxSx = inputSx ? inputSx: style;
    let jsMaxDate = maxDate ? dayjs(maxDate) : dayjs('9999-12-31T23:59:59');
    let jsMinDate = minDate ? dayjs(minDate) : dayjs('1900-01-01T00:00:00');
    const [error, setError] = React.useState(errorText);
    const [isError, setIsError] = React.useState(false);

    const errorMessage = React.useMemo(() =>
     {
        if(errorText){
            setIsError(true);
            return errorText;
        }
        if(error) console.log('Error with date: ', error);
        switch (error) {
          case 'maxDate': {
            return `Please select a date before ${jsMaxDate.format('DD/MM/YYYY')}`;
          }
          case 'minDate': {
            return `Please select a date after ${jsMinDate.format('DD/MM/YYYY')}`;
          }
          case 'invalidDate': {
            setIsError(true);
            return 'Your date is not valid';
          }
    
          default: {
            setIsError(false);
            return '';
          }
        }
      }, [error, errorText, minDate, date, setDate]);

    return (
        <DatePicker
            minDate={jsMinDate}
            maxDate={jsMaxDate}
            label={label ? label : 'Date'}
            value={!date ? null : dayjs(date)}
            format="DD/MM/YYYY"
            sx={sx ? sx : {width:'100%'}}
            disabled = {disabled}
            onError={(newError) => setError(newError)}
            onChange={(date) => {
                if(date){
                    setDate(date.toDate());
                }
                else    
                {
                    setDate(null);
                }
            }}
            slotProps={slotProps ? slotProps : 
                {
                    textField:{
                        size: 'small',
                        sx:{...textBoxSx},
                        required:required,
                        error: isError,
                        helperText: errorMessage
                    }
                }
            }
        />
    )
}

export default DateSelector;