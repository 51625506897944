import React, { useState }  from 'react';
import { useMsal } from "@azure/msal-react";
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {  useGetFundIndicatorsApi, usePutFundIndicatorsApi } from '../../../hooks/useApis';
import errorMessageToDisplay from '../../../services/error';
import DateSelector from '../../../components/DateSelector'
import { Grid, TextField, MenuItem } from '@mui/material';
import dayjs from 'dayjs';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddIndicatorToFund = React.memo((props) => {
    const { closeDialogCallback, setIsLoading, setErrorMessage, fundIndicator, setFundIndicator } = props;
    const [open, setOpen] = React.useState(true);
    const [validFromError, setValidFromError] = useState('');
    const [validFromDate, setValidFromDate] = useState(fundIndicator.validFromDate ? dayjs(fundIndicator.validFromDate).toDate() : null);
    const [validToDate, setValidToDate] = useState(fundIndicator.validToDate ? dayjs(fundIndicator.validToDate).toDate() : null);
    const { execute:getFundIndicators } = useGetFundIndicatorsApi();
    const { execute:putFundIndicators } = usePutFundIndicatorsApi();

    const fieldsAreValid = () => {
      var fieldsAreValid = true;
      if (!validFromDate) {
        setValidFromError("Valid From Date is required");
        fieldsAreValid = false;
      }
      if (validToDate && validFromDate > validToDate) {
        setValidFromError("Valid From Date must be on or before Valid To Date");
        fieldsAreValid = false;
      }      
      return fieldsAreValid;
    }

    const updateValidFromDate =  (date) => {
      // N.B. if I don't do this, then the date uses timezone info and is a day out at certain times of year
      if(date){
        let stringDate = dayjs(date).format('YYYY-MM-DD'); 
        setValidFromDate(stringDate);
      } else {
        setValidFromDate(null);
      }
      
      setValidFromError('');
    };

    const updateValidToDate = (date) => {
      // N.B. if I don't do this, then the date uses timezone info and is a day out at certain times of year
      if(date){
        let stringDate = dayjs(date).format('YYYY-MM-DD'); 
        setValidToDate(stringDate);
      } else {
        setValidToDate(null);
      }
      setValidFromError('');
    };     

    const handleSave = () => {
      if(!fieldsAreValid()) {
        return;
      }
      fundIndicator.validFromDate = validFromDate ? dayjs(validFromDate).format('YYYY-MM-DD'): null;
      fundIndicator.validToDate = validToDate ? dayjs(validToDate).format('YYYY-MM-DD'): null;
      setIsLoading(true);
      putFundIndicators(fundIndicator)
      .then((response) => {
        getFundIndicators(fundIndicator.fundCode, fundIndicator.indicatorId).then((response) => {
          console.log("putFundIndicator Response: ", response);
          let updatedFundIndicator = response;
          setFundIndicator(updatedFundIndicator);
          setIsLoading(false);
          if(closeDialogCallback) closeDialogCallback();
        })
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e)
        setErrorMessage(errorMessageToDisplay);
      })                   
    }
  
    const handleClose = () => {
      setOpen(false);
      if(closeDialogCallback) closeDialogCallback();
    }

    return (
        <>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
            maxWidth="lg"
          >
            <DialogTitle>{"Modify Fund Indicator"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3}><label><b>Indicator:</b></label></Grid>
                  <Grid item xs={12} sm={9}>
                      <TextField select readOnly defaultValue={fundIndicator} fullWidth>
                              <MenuItem key={fundIndicator.indicatorId} value={fundIndicator}>
                                {fundIndicator["_embedded"].indicator.name}
                              </MenuItem>
                      </TextField>
                  </Grid>                  

                  <Grid item xs={12} sm={3}><label><b>Valid From Date:</b></label></Grid>
                  <Grid item xs={12} sm={9}><DateSelector date={validFromDate} sx={{width:10}} setDate={updateValidFromDate} label="Valid From" errorText={validFromError} required={true}/></Grid>                  

                  <Grid item xs={12} sm={3}><label><b>Valid To Date (Optional):</b></label></Grid>
                  <Grid item xs={12} sm={9}><DateSelector date={validToDate} sx={{width:10}} setDate={updateValidToDate} label="Valid To (Optional)"/></Grid>                  
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>                    
          </Dialog>
        </>
      );

});

export default AddIndicatorToFund;