import './styles/App.css';
import React, { useEffect, useState } from 'react';
import { PageLayout } from './components/PageLayout';
import { Home } from './components/Home';
import { FundPage } from './pages/fund-page/FundPage';
import { Indicators } from './components/Indicators';
import { IndicatorDetail } from './components/IndicatorDetail';
import { Metrics } from './components/Metrics';
import { Routes, Route, useLocation  } from "react-router-dom";

function normaliseName(name) {
  let names = name.split(', ');
  return names[1] + ' ' + names[0];
}

let routes = [
    {path:"/", key:"default", name:"Home", isMenuItem: true, requiredRoles: null},
    {path:"/fund", key:"/fund", name:"Fund", isMenuItem: true, requiredRoles: ["Maintenance", "Support"]},
    {path:"/indicators", key:"/indicators", name:"Indicators", isMenuItem: true, requiredRoles: ["Maintenance", "Support"]},
    {path:"/indicators/:id", key:"/indicators/:id", name:"IndicatorDetail", isMenuItem: false, requiredRoles: ["Maintenance", "Support"]},
    {path:"/metrics", key:"/metrics", name:"Metrics", isMenuItem: true, requiredRoles: ["Maintenance", "Support"]},
    {path:"*", key:"404", name:"Page Not Found", isMenuItem: false, requiredRoles: null},
];

let route404 = routes.find((route) => route.key === '404');

const getUserRoutes = (userRoles) => {
   let userIsAuthenticedWithRoles = userRoles && userRoles.length > 0;
   let userRoutes = routes.filter((route) => !route.requiredRoles || (userIsAuthenticedWithRoles && route.requiredRoles.some(role => userRoles.includes(role))));
   return userRoutes;
}

const Pages = ({userRoutes, username, user}) => {
    return (
        <Routes>
          {
            userRoutes.map((route) => (
                <Route exact key={route.key} path={route.path} element={
                route.name === "Metrics" ? <Metrics user={user}/>
              : route.name === "Fund" ? <FundPage user={user}/>
              : route.name === "Indicators" ? <Indicators user={user}/>
              : route.name === "Home" ? <Home displayFriendlyUserName={username} user={user} />
              : route.name === "IndicatorDetail" ? <IndicatorDetail user={user}/>
              : route.key === "404" ? <h1>404 - Page Not Found</h1>
              : null
             }/>
             ))
          }
        </Routes>
    );
};

export default function App(props) {
  const { msalInstance } = props;
  const [activeAccount, setActiveAccount] = useState(msalInstance?.activeAccount);
  const [userRoles, setUserRoles] = useState(null);
  const [userRoutes, setUserRoutes] = useState(null);
  const [userMenuRoutes, setUserMenuRoutes] = useState(null);
  const [userName, setUserName] = useState(null);
  const [user, setUser] = useState(null);
  const [currentRoute, setCurrentRoute] = useState(null);
  const routerLocation = useLocation();
  const { pathname } = routerLocation;

  useEffect(() => {
    if(msalInstance) {
      setActiveAccount(msalInstance.getActiveAccount());
    } else{
      setActiveAccount(null);
    }
  }, [msalInstance]);

  useEffect(() => {
    if(pathname && userRoutes) {
        let route = userRoutes.find((route) => route.path === pathname);
        if(route) setCurrentRoute(route);
        else setCurrentRoute(route404);
    }
  }, [pathname, userRoutes]);

  useEffect(() => {
    if(activeAccount) {
        let userRoles = activeAccount?.idTokenClaims ? activeAccount?.idTokenClaims['roles'] : null;
        setUserRoles(userRoles);
        setUserName(normaliseName(activeAccount.name));
        let user = {
            name: activeAccount?.name,
            roles: !userRoles? [] : userRoles,
            hasMaintainerRole: userRoles && userRoles.includes('Maintenance'),
            hasSupportRole: userRoles && userRoles.includes('Support')
        }
        setUser(user);
        console.log('USER: ', user);
        console.log('USER ROLES: ', userRoles);
        console.log('USERNAME: ', activeAccount.name);
    }else{
        setUserRoles(null);
        setUserName(null);
        setUser(null);
    }
  }, [activeAccount]);

  useEffect(() => {
    let userRoutes = getUserRoutes(userRoles);
    setUserRoutes(userRoutes);
    let menuRoutes = userRoutes.filter((route) => route.isMenuItem === true);
    setUserMenuRoutes(menuRoutes);
  }, [userRoles]);

    return (
      <>
         {currentRoute && userRoutes ?
            <PageLayout pageTitle={currentRoute.name} username={userName} user={user} routes={userMenuRoutes}>
                <Pages userRoutes={userRoutes} username={userName} user={user}/>
            </PageLayout>
        :null}
      </>
    );
}
