import React from 'react';
import './AppNavBar.css';
import logo from '../images/MG_pos_rgb_1.0.png';
import { NavLink } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import { useMsal } from "@azure/msal-react";
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import { AuthenticatedTemplate } from "@azure/msal-react";

export const PageLayout = (props) => {
    const {username, routes, children, user} = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const { instance } = useMsal();
    const menuId = 'primary-search-account-menu';
    const isMenuOpen = Boolean(anchorEl);
    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };    
    const handleLogout = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
            account: instance.getActiveAccount()
        });
    }

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <AuthenticatedTemplate>
                <Box style={{borderBottom: '2px solid gray', margin: '1em'}}>
                    <Box>
                        {username}
                    </Box>
                    <Box>
                        <Box>Roles:</Box>
                        <ul>
                        {!user?.roles ? null : user.roles.map((item,i) => { return <li key={i}>{item}</li>})}
                        </ul>
                        <br/>
                    </Box>
                </Box>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                <MenuItem onClick={handleMenuClose}><NavLink style={{textDecoration: 'none'}} to={`https://myaccount.microsoft.com/?ref=MeControl&login_hint=${username}`}>My account</NavLink></MenuItem>
            </AuthenticatedTemplate>
        </Menu>
    );

    return (
        <>
            <div className="navbar-fixed-top navbar navbar-expand-lg navbar-light">
                <NavLink to="/" className="navbar-brand LogoBrand">
                    <img style={{height: '5em', width: '5em'}}
                        alt=""
                        src={logo}
                        width="80"
                        height="80"
                    />
                </NavLink>
                <NavLink className="navbar-brand " to="/">
                <div className="AppTitle" style={{marginTop:'-1.25em'}}>
                    <Typography className="AppTitle-AppName" variant="h5" component="div" style={{margin: '0.2em 0',padding: '0em', verticalAlign: 'text-top', lineHeight:'100%'}}>ESG Metric Master</Typography>
                    <Typography className="AppTitle-SubHeader" variant="h9" component="div" style={{marginTop: '0',padding: '0em', verticalAlign: 'text-top', lineHeight:'100%'}}>Maintaining ESG indicators & metrics</Typography>
                </div>
                </NavLink>
                <AuthenticatedTemplate>
                    <div className="justify-content-start navbar-collapse" id="menu">
                        <div className="MenuOptions">
                            {routes ? routes.map(route => 
                            <NavLink key={route.path} className="MenuOption navbar-nav ml-auto vertical" to={route.path}
                                style={({ isActive, isPending }) => {
                                return {
                                    color: isPending?'#d13c15': isActive ? "#d13c15" : "#2f2f2f"
                                };
                                }}                  
                            >{route.name}
                            </NavLink>
                            )
                            :null}
                        </div>
                    </div>     
                    <div>
                    <Toolbar>           
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>                                
                            <IconButton
                                size="large"
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="gray"
                                >
                                <AccountCircle />
                            </IconButton>
                        </Box>
                    </Toolbar>
                    {renderMenu}
                    </div>
                </AuthenticatedTemplate>
            </div>
                <div>    
                    <hr />
                    <br />
                    <Container maxWidth="xl" component="main">
                    {                    
                        children
                    }
                    </Container>
                </div>   
    </>
    );
};
