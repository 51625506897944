import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import IconButton from '@mui/material/IconButton';
import SavedSearchIcon from '@mui/icons-material/SavedSearch';
import SearchIcon from '@mui/icons-material/Search';
import TableFilter from './TableFilter';
import Box from '@mui/material/Box';
import { Collapse } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  borderBottomStyle: 'solid',
  lineHeight: '3em',
  padding: theme.spacing(0.5)
}));

const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  border: 0,
  lineHeight: 1,
  padding: theme.spacing(0.5)
}));

const GroupDropDownTableCell = styled(TableCell)(({ theme }) => ({
  maxWidth: '2em',
  minWidth: '2em',
  borderBottom:'none',
  lineHeight: 1,
  paddingLeft: 5
}));

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 750,
  width:"100%",
  margin:0,
  padding:0
}));

const isOrderedBy = (orderBy, columnSetting) => {
  return orderBy && columnSetting && orderBy.id === columnSetting.id;
};

function descendingComparator(a, b, orderBy) {
  let aData = orderBy.getData ? orderBy.getData(a) : a[orderBy.id];
  let bData = orderBy.getData ? orderBy.getData(b) : b[orderBy.id];

  if (!bData && aData) {
    return 1;
  } 
  if (bData && !aData) {
    return -1;
  }    
  if (bData < aData) {
    return -1;
  }
  if (bData > aData) {
    return 1;
  }
  return 0;
}

function getSortOrderComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function prepareData(array, comparator, columnSettings, filterCount, setFilterCount) {
  let filtered = filterByColumnFilters(array, columnSettings, filterCount, setFilterCount);
  if(filtered.sort)
    filtered = filtered.sort(comparator);
  return filtered;
}

const alignmentForType = (type) => {return type === "checkbox" ? 'center' : type === "numeric" ? "right" : 'left'};

function filterByColumnFilters(array, columnSettings, setFilterCount) {
  let result = array.filter(row => {
     let shouldKeep = true;
     columnSettings.cells.filter(cell => !cell["grouped"]).forEach(columnSetting => {
        if(columnSetting.filter && columnSetting.filter.value) {
          var cellValue = columnSetting.getData ? columnSetting.getData(row) : row[columnSetting.id];
          shouldKeep = shouldKeep && columnSetting.filter.comparator(cellValue, columnSetting.filter.value)
        }
     });
     columnSettings.cells.filter(cell => cell["grouped"]).forEach(columnSetting => {
      if(columnSetting.filter && columnSetting.filter.value) {
        var cellValue = columnSetting.getData ? columnSetting.getData(row) : row[columnSetting.id];
        shouldKeep = shouldKeep && columnSetting.filter.comparator(cellValue, columnSetting.filter.value)
      }
   });

   return shouldKeep;
  });
  if(result.length > 0 && result.length !== array.length && setFilterCount) {
    setFilterCount(result.length);
  }
  return result;
}

function GroupedTableHead(props) {
  const { columnSettings } = props;
  
  return (
    <TableHead>
      <TableRow>
      {columnSettings.cells.filter(cell => cell.grouped).map((columnSetting) => (
          <StyledTableHeaderCell 
            key={columnSetting.id}
            align={alignmentForType(columnSetting.type)}
            padding={columnSetting.disablePadding ? 'none' : 'normal'}
            style={columnSetting.style}
          >
            {columnSetting.label}
          </StyledTableHeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableHead(props) {

  const { data, setColumnSettings, columnSettings, order, orderBy, onRequestSort } = props;
  const createSortHandler = (columnSetting) => (event) => {
    onRequestSort(event, columnSetting);
  };

  const showFilter = (event, column, columnSettings) => {
    column.filter.button = event.currentTarget;
    column.filter.show = Boolean(event.currentTarget);
    setColumnSettings({...columnSettings});
  };

  const handleCloseFilter = (event, column, columnSettings) => {
    column.filter.button = null;
    column.filter.show = Boolean(false);
    setColumnSettings({...columnSettings});
  };

  return (
    <TableHead>
      <TableRow>
        {columnSettings.settings.hasGroup? <StyledTableHeaderCell/> : null}
        {columnSettings.cells.filter(cell => !cell.grouped && !cell.hide).map((columnSetting) => (
          <StyledTableHeaderCell
            key={columnSetting.id}
            align={alignmentForType(columnSetting.type)}
            padding={columnSetting.disablePadding ? 'none' : 'normal'}
            sortDirection={isOrderedBy(orderBy, columnSetting) ? order : false}
            style={columnSetting.style}
          >
            <Box sx={{whiteSpace: 'nowrap'}}>
              {!columnSetting.sort || columnSetting.sort.enabled 
               ?
                <TableSortLabel
                  active={isOrderedBy(orderBy, columnSetting)}
                  direction={isOrderedBy(orderBy, columnSetting) ? order : 'asc'}
                  onClick={createSortHandler(columnSetting)}
                >
                  {columnSetting.label}
                  {isOrderedBy(orderBy, columnSetting) ? (
                    <span style={{
                      border: 0,
                      clip: 'rect(0 0 0 0)',
                      height: 1,
                      margin: -1,
                      overflow: 'hidden',
                      padding: 0,
                      position: 'absolute',
                      top: 20,
                      width: 1}}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
                : columnSetting.label}
              {columnSetting.filter ?
                <span>
                  <IconButton
                    aria-label="filter list"
                    onClick={e => showFilter(e, columnSetting, columnSettings)}
                    disableFocusRipple={true}
                    disableRipple={true}
                    edge="start"
                    size="large">
                    {columnSetting.filter.value ? <SavedSearchIcon /> : <SearchIcon/>}
                  </IconButton>
                  <Popover
                    sx={{width: '100%', display: 'block'}}
                    id={columnSetting.id + '.search-popover'}
                    open={columnSetting.filter.show === undefined || columnSetting.filter.show === null? Boolean(false): columnSetting.filter.show}
                    anchorEl={columnSetting.filter.button}
                    onClose={e => handleCloseFilter(e, columnSetting, columnSettings)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                     <TableFilter dataObject={data} searchFilter={columnSetting.filter.searchFilter} columnSetting={columnSetting} columnSettings={columnSettings} setColumnSettings = {setColumnSettings} key={columnSetting.id + "-filter"}/>
                  </Popover>
                </span>
              : null}
            </Box>
          </StyledTableHeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.object.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function DataCellContent ({render, dataRow, childRow, rowIndex, data, type, dataValue}) {

  // This is messy as trying to cater for code, the render method should be the same method signature for both renders!
  if(render && !childRow) return render(dataRow, data, rowIndex);
  if(render && childRow) return render(dataRow, childRow, data)

  if(type==="bool"){
    if(data === undefined || data === null) return null;
    if(data) return  <Checkbox checked={dataValue} disabled={true} />
  } else{
    return dataValue;
  }
}

function DataRow(props) {
  const { dataRow, columnSettings, rowIndex, data, onRowClick } = props;
  const [open, setOpen] = React.useState(false);

  // TODO SP
  const isItemSelected = false;
  // const isItemSelected = (dataRow.entityType ==="Global")? dataRow.expected : dataRow.entities.find(entity => entity.expected) !== undefined;

  return (
    <>
    <TableRow
      hover
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      // TODO come back for shareclasses
      key={`parent-${rowIndex}`}
      selected={isItemSelected}
      onClick={(event) => (onRowClick) ? onRowClick(event, dataRow) : {}}
    >
      {columnSettings.settings.hasGroup?
      <GroupDropDownTableCell>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
          style={{padding:0, margin:0}}
          >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </GroupDropDownTableCell>
      : null}
      {columnSettings.cells.filter(cell => !cell.grouped && !cell.hide).map((col, columnIndex) => (
        <StyledTableBodyCell key={col.id} align={alignmentForType(col.type)} padding={col.type==="checkbox"?"checkbox":null} style={col.style}>
          <DataCellContent render={col.render} dataRow={dataRow} rowIndex={rowIndex} data={data} type={col.type} dataValue={col.getData?col.getData(dataRow) : dataRow[col.id]}/>
        </StyledTableBodyCell>
       ))}
    </TableRow>
    {columnSettings.settings.hasGroup?
    <TableRow key={`parent-group-${rowIndex}`}>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={columnSettings.cells.length}>
        <Collapse in={open} timeout="auto" unmountOnExit sx={{pl: 13}}>    
            <Table >
            <GroupedTableHead columnSettings={columnSettings}/>
            <TableBody>
            {columnSettings.grouping.groupBy(data, dataRow).map((childRow, childIndex) =>{
            return(
            <TableRow
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={`group-${rowIndex}-child-${childIndex}`}
              style={{ paddingBottom: 0, paddingTop: 0 }} 
              selected={isItemSelected}
              >
              {columnSettings.cells.filter(cell => cell.grouped && !cell.hide).map((col) => (
                  <StyledTableBodyCell key={col.id} align={alignmentForType(col.type)} padding={col.type==="checkbox"?"checkbox":null}>
                    <DataCellContent render={col.render} dataRow={dataRow} childRow={childRow} rowIndex={rowIndex} data={data} dataValue={col.getData?col.getData(childRow) : childRow[col.id]} type={col.type}/>
                  </StyledTableBodyCell>
                ))}
              </TableRow>)})}
              </TableBody>
            </Table>
        </Collapse>
      </TableCell>
    </TableRow>
    : null}
    </>
  )
}
const DataTable = ({data, setData, filterCallback, setFilterCount, page, rowsPerPage, columnSettings, setColumnSettings, onRowClick, tableContainerSx, ...props}) => {
  const dense = true;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(columnSettings ? columnSettings.cells.find(cell => cell.id === columnSettings.settings.defaultSortColumn): null);
  

  const handleRequestSort = (event, columnSetting) => {
    const isAsc = isOrderedBy(orderBy, columnSetting) && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(columnSetting);
  };

  


  var filteredAndSortedData = prepareData(data, getSortOrderComparator(order, orderBy), columnSettings, setFilterCount);

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, filteredAndSortedData === null ? 0 : filteredAndSortedData.length - page * rowsPerPage);

  return (
    <TableContainer style={{width:"100%", overflow: "none", margin:0, padding:0}} sx={tableContainerSx}>  
        <StyledTable
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
            stickyHeader 
          >
            <EnhancedTableHead
              setColumnSettings={setColumnSettings}
              columnSettings={columnSettings}
              data={data}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
              <TableBody>
                {
                  filteredAndSortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((dataRow, index) => (
                    <DataRow dataRow={dataRow} columnSettings={columnSettings} data={data} key={index} rowIndex={index} onRowClick={onRowClick}/> 
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
        </StyledTable>        
      </TableContainer>
  );
}

export default DataTable;