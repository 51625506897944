import React, { useEffect, useState } from 'react';
import { getMetrics, getMetricDetails, getMetricValueUnitTypes, getMetricSourceFields } from '../services/api';
import Box from '@mui/material/Box';
import DataTable from './DataTable';
import { textSearch, dataForColumn } from '../services/tableUtils';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import CustomTablePagination from './CustomTablePagination';
import { Alert, AlertTitle } from '@mui/material'
import errorMessageToDisplay from '../services/error';
import EditMetricDetail from './EditMetricDetail';
import MetricDetail from './MetricDetail';
import AddMetricDetail from './AddMetricDetail';
import Button from '@mui/material/Button';
import { AuditInfoTooltip } from './AuditInfoTooltip';

const Metric = (props) => { 
  const { user } = props;

  const [metrics, setMetrics] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [metric, setMetric] = React.useState({});
  const [edit, setEdit] = React.useState(false);
  const [add, setAdd] = React.useState(false);
  const [unitValueTypes, setUnitValueTypes] = useState([]);
  const [sourceFields, setSourceFields] = useState([]);

  const openMetricDetails = (event, metric) => {
    setIsLoading(true);
    getMetricDetails(metric.id)
    .then((response) => {
      setMetric(response);   
      console.log(metric); 
      getMetricValueUnitTypes()
      .then((response) => {
        setUnitValueTypes([...response]);
        getMetricSourceFields()
        .then((resp) => {
          setSourceFields([...resp]);
          setOpen(true);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setErrorMessage(errorMessageToDisplay);
        })  
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(errorMessageToDisplay);
      })  
    })
    .catch((e) => {
      setIsLoading(false);
      console.log(e)
      setErrorMessage(errorMessageToDisplay);
    })      
  };

  const getMetricsList = async () => {    
    getMetrics()
    .then((response) => {
      setMetrics([...response]);
      setIsLoading(false);
    })
    .catch((e) => {
      setIsLoading(false);
      setErrorMessage(errorMessageToDisplay);
    })        
  }

  useEffect(() => {
    getMetricsList()
  }, []);

  const MetricsTable = ({data, setData }) => {
    const [dataCount, setDataCount] = useState(data.length);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [columnSettings, setColumnSettings] = useState(
    {
        settings:{
            defaultSortColumn: 'name',
            hasGroup: false
        },
        cells:[
            { id: 'auditInfo', type: "string", disablePadding: false, label: '', style: { width: "5%" }, 
                sort: {enable: false}, render: (row, data, index) => <AuditInfoTooltip row={row}/>},
            { id: 'name', type: "string", disablePadding: false, label: 'Name', style: { width: "45%" }, filter: {getColumnData: dataForColumn, comparator: textSearch, value:null}},
            { id: 'displayName', type: "string", disablePadding: false, label: 'Display Name', style: { width: "40%" }, filter: {getColumnData: dataForColumn, comparator: textSearch, value:null}},
            { id: 'valueUnitType', type: "string", disablePadding: false, label: 'Value Unit Type', style: { width: "10%" }, filter: {getColumnData: dataForColumn, comparator: textSearch, value:null}}
        ]
    });

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }; 

    const addNewMetric = () => {
      setIsLoading(true);
      getMetricValueUnitTypes()
      .then((response) => {
        setUnitValueTypes([...response]);
        getMetricSourceFields()
        .then((resp) => {
          setSourceFields(resp);
          setAdd(true);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          setErrorMessage(errorMessageToDisplay);
        }) 
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(errorMessageToDisplay);
      })        
    }
  
    return (
        <Box sx={{width: "100%"}}>
            <DataTable
                id="MetricsTable"
                data={data}
                setData={setData}
                setFilterCount={setDataCount}
                page={page}
                rowsPerPage={rowsPerPage}
                columnSettings={columnSettings}
                setColumnSettings={setColumnSettings}
                onRowClick={openMetricDetails}
                />   
            <br/>
            <div style={{float: 'right'}}>
              <CustomTablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={3}
                  slotProps={{
                    select: {
                      'aria-label': 'rows per page',
                    },
                    actions: {
                      showFirstButton: true,
                      showLastButton: true,
                    },
                  }}
                  count={dataCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  />   
            </div>      
            {edit === true 
              ? 
                <EditMetricDetail metric={metric} open={open} setOpen={setOpen} setEdit={setEdit} metrics={metrics} setMetrics={setMetrics} setIsLoading={setIsLoading} setErrorMessage={setErrorMessage} unitValueTypes={unitValueTypes} sourceFields={sourceFields}/>          
              :
                add === true 
                ?
                  <AddMetricDetail setAdd={setAdd} unitValueTypes={unitValueTypes} setIsLoading={setIsLoading} setErrorMessage={setErrorMessage} sourceFields={sourceFields} setMetrics={setMetrics}/>
                :
                  <MetricDetail metric={metric} open={open} setOpen={setOpen} setEdit={setEdit} user={user}/>         
            }
            <br/>
            {
              user.hasMaintainerRole === true 
              ? 
                <div style={{float: 'left'}}>
                  <Button variant="outlined" onClick={addNewMetric}>Add</Button>
                </div> 
              : <></>
            }
        </Box>        
    );
  }
  

  return (
    <>      
      <br/>
      {isLoading === true 
        ? 
          <Backdrop open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
          
        : 
          errorMessage !== "" 
          ? <Alert severity="error">
              <AlertTitle>Error</AlertTitle>  
              {errorMessage}
            </Alert> 
          :
            <div className="App">
              <MetricsTable
                id="MetricsTable"
                data={metrics}
                setData={setMetrics}
              />
            </div>
        }
    </>
  )
};

export const Metrics = (props) => {   
    const { user } = props; 
    return (
      <>
          <Metric user={user}/>
      </>
    )
};