import React, { useState, useEffect }  from 'react';
import { Grid, TextField, Link } from '@mui/material';
import { updateMetric, getMetricByName } from '../services/api';
import { useMsal } from "@azure/msal-react";
import errorMessageToDisplay from '../services/error';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DropdownField from './DropdownField';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const EditMetricDetail = (props) => {
    const { metric, open, setOpen, setEdit, metrics, setMetrics, setIsLoading, setErrorMessage, unitValueTypes, sourceFields } = props;
    const { instance } = useMsal();
    const [metricCopy, setMetricCopy] = useState({...metric});
    const [nameIsValid, setNameIsValid] = useState(true);
    const [displayNameIsValid, setDisplayNameIsValid] = useState(true);
    const [valueUnitSourceIsValid, setValueUnitSourceIsValid] = useState(true);
    const [metricValueSourceFields, setMetricValueSourceFields] = useState([]);
    const [metricValueTypes, setMetricValueTypes] = useState([]);
    const [newValueUnitSource, setNewValueUnitSource] = useState(false);
    
    const errorText = 'Field cannot be empty';
    const [nameError, setNameError] = useState('');
    const [displayNameError, setDisplayNameError] = useState('');
    const [valueUnitTypeError, setValueUnitTypeError] = useState('');

    const setSourcesToDisplay = (sourceProductSuite) => {
      if(sourceProductSuite == null || sourceProductSuite.trim() === '') {
        sourceProductSuite = sourceFields[0].sourceProductSuite;
      }
      const filteredItems = sourceFields.filter((item) => item.sourceProductSuite === sourceProductSuite);
      setMetricValueSourceFields(filteredItems.length > 0 ? filteredItems[0].sourceFieldNames : []);
      setMetricValueTypes(filteredItems.length > 0 ? filteredItems[0].metricValueTypes : []);
    }

    useEffect(() => {
      setSourcesToDisplay(metric.sourceProductSuite)
    }, []);

    function mapTypesToOptions(types) {
      return types.map((type) => ({
        label: type,
        value: type,
      }));
    }

    const handleFieldChange = (event, field) => {
      if(field === 'name' && nameIsValid === false) {
        setNameIsValid(true);
        setNameError('');
      }
      else if(field === 'displayName' && displayNameIsValid === false) {
        setDisplayNameIsValid(true);
        setDisplayNameError('');
      }
      else if(field === 'valueUnitType' && valueUnitSourceIsValid === false) {
        setValueUnitSourceIsValid(true);
        setValueUnitTypeError('');
      }
      else if(field === 'sourceProductSuite') {
        setSourcesToDisplay(event.target.value)
      }
      
      setMetricCopy({
          ...metricCopy,
          [field]: event.target.value
      });
      console.log(metricCopy);
    }

    const fieldsAreValid = () => {
      let fieldsAreValid = true;
      if(metricCopy.name == null || metricCopy.name.trim() === '') {
        setNameIsValid(false);
        setNameError(errorText);
        fieldsAreValid = false;
      }
      if(metricCopy.displayName == null || metricCopy.displayName.trim() === '') {
        setDisplayNameIsValid(false);
        setDisplayNameError(errorText);
        fieldsAreValid = false;
      }
      if(metricCopy.valueUnitType == null || metricCopy.valueUnitType.trim() === '') {
        setValueUnitSourceIsValid(false);
        setValueUnitTypeError(errorText);        
        fieldsAreValid = false;
      }
      return fieldsAreValid;
    }

    const handleSave = () => {
      console.log(metricCopy);
      if(!fieldsAreValid()) {
        return;
      }
      
      if(metric.name !== metricCopy.name) {
        getMetricByName(metricCopy.name)
        .then((response) => {
          if(response.metricExists != null && response.metricExists === false) {
            setIsLoading(true);
            updateMetric(metricCopy, instance.getActiveAccount().username)
            .then(() => {
              setIsLoading(false);
              var metricIndex = metrics.findIndex((obj => obj.id == metric.id));
              metrics[metricIndex] = metricCopy;
              setMetrics([...metrics]);
              setEdit(false);
              setOpen(false);
            })
            .catch((e) => {
              setIsLoading(false);
              console.log(e)
              setErrorMessage(errorMessageToDisplay);
            })   
          }
          else {          
            setNameIsValid(false);
            setNameError('Metric name already exists');
            return;          
          }
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e)
          setErrorMessage(errorMessageToDisplay);
        })    
      }
      else {
        setIsLoading(true);
        updateMetric(metricCopy, instance.getActiveAccount().username)
        .then(() => {
          setIsLoading(false);
          var metricIndex = metrics.findIndex((obj => obj.id == metric.id));
          metrics[metricIndex] = metricCopy;
          setMetrics([...metrics]);
          setEdit(false);
          setOpen(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e)
          setErrorMessage(errorMessageToDisplay);
        })    
      }   
    }
  
    const handleClose = () => {
      setEdit(false);
      setOpen(true);
    }

    const sourceProductSuitesToDisplay = (sourceFields) => {
      return sourceFields.map((sourceField) => sourceField.sourceProductSuite);
    }

    const handleNewValueUnitClick = () => {
      setNewValueUnitSource(true);
    };
  
    const handleExistingValueUnitClick = () => {
      setNewValueUnitSource(false);
    };
    
    return (
      <>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogTitle>{"Metric Detail"}</DialogTitle>
          <DialogContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={3}><label><b>Name:</b></label></Grid>
                <Grid item xs={12} sm={9}><TextField required value={metricCopy.name} error={!nameIsValid} helperText={nameError} fullWidth onChange={(event) => handleFieldChange(event, 'name')}/></Grid>
                <Grid item xs={12} sm={3}><label><b>Display Name:</b></label></Grid>
                <Grid item xs={12} sm={9}><TextField required value={metricCopy.displayName} error={!displayNameIsValid} helperText={displayNameError} fullWidth onChange={(event) => handleFieldChange(event, 'displayName')}/></Grid>
                {newValueUnitSource === true ?
                    <>
                      <Grid item xs={12} sm={3}><label><b>Value Unit Type:</b></label> <br/>                  
                        <Link href="#" onClick={handleExistingValueUnitClick} style={{ textAlign: 'center', alignSelf: 'flex-end' }}>
                          Existing
                        </Link>                
                      </Grid>  
                      <Grid item xs={12} sm={9}><TextField required value={metricCopy.valueUnitType} error={!valueUnitSourceIsValid} helperText={valueUnitTypeError} fullWidth onChange={(event) => handleFieldChange(event, 'valueUnitType')}/></Grid>
                    </>
                  :
                    <>
                      <Grid item xs={12} sm={3}><label><b>Value Unit Type:</b></label> <br/>                  
                        <Link href="#" onClick={handleNewValueUnitClick} style={{ textAlign: 'center', alignSelf: 'flex-end' }}>
                          New
                        </Link>  
                      </Grid>
                      <Grid item xs={12} sm={9}><DropdownField options={mapTypesToOptions(unitValueTypes)} value={metricCopy.valueUnitType} onChange={(event) => handleFieldChange(event, 'valueUnitType')} /></Grid>
                    </>                    
                  }
                <br/>
                <br/>
                <br/>
                <Grid item sm={3}>
                  <h4>Source Fields</h4>
                </Grid>
                <Grid item sm={9}></Grid>
                <Grid item xs={12} sm={3}><label><b>Source Product Suite:</b></label></Grid>
                <Grid item xs={12} sm={9}><DropdownField options={mapTypesToOptions(sourceProductSuitesToDisplay(sourceFields))} value={metricCopy.sourceProductSuite} onChange={(event) => handleFieldChange(event, 'sourceProductSuite')} /></Grid>
                <Grid item xs={12} sm={3}><label><b>Metric Value Source Field:</b></label></Grid>
                <Grid item xs={12} sm={9}><DropdownField options={mapTypesToOptions(metricValueSourceFields)} value={metricCopy.sourceDataProductMetricTypeCode} onChange={(event) => handleFieldChange(event, 'sourceDataProductMetricTypeCode')} /></Grid>
                <Grid item xs={12} sm={3}><label><b>Metric Value Type:</b></label></Grid>
                <Grid item xs={12} sm={9}><DropdownField options={mapTypesToOptions(metricValueTypes)} value={metricCopy.metricValueType} onChange={(event) => handleFieldChange(event, 'metricValueType')} /></Grid>
              </Grid>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleSave}>Save</Button>
              <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>                    
        </Dialog>
      </>
    );
  };

  export default EditMetricDetail;