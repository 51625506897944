import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.css';
import App from './App';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig, setMsalInstance } from './services/authConfig';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from '@azure/msal-react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from './services/authConfig';

const theme = createTheme({
    palette: {
        primary: {
          light: '#377B7F',
          main: '#055A60',
          dark: '#04484C',
          contrastText: '#fff'
        },
        secondary: {
          light: '#377B7F',
          main: '#055A60',
          dark: '#04484C',
          contrastText: '#fff'
        },
        success:{
          light: '#CCE3BF',
          main: '#98C87F',
          dark: '#7EBD5F',
        } ,            
        text:{
          primary: "#2F2F2F",
          secondary: "#055A60"
        }
      },
    
      typography: {
        fontSize: 16,
        color: "#2F2F2F",
        fontFamily: [
          'FFInfraRegular',
          'Calibri',
          'cursive',
        ].join(','),
      },
  });

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
setMsalInstance(msalInstance);

const authRequest = {
  ...loginRequest
};
root.render(
    // <StrictMode> MUST be disabled due to a bug in the MSAL library
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <BrowserRouter>
                      <MsalProvider instance={msalInstance}>
                        <MsalAuthenticationTemplate 
                          interactionType={InteractionType.Redirect} 
                          authenticationRequest={authRequest}
                          >
                           <App msalInstance={msalInstance}/>
                        </MsalAuthenticationTemplate>
                      </MsalProvider>
                    </BrowserRouter>
                  </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    // </StrictMode>
);
