import React, { useState }  from 'react';
import { useMsal } from "@azure/msal-react";
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, TextField, Checkbox } from '@mui/material';
import { addIndicator, getIndicatorByNameTypeAndReg, getIndicators } from '../services/api';
import errorMessageToDisplay from '../services/error';
import DropdownField from './DropdownField';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddIndicator = React.memo((props) => {
    const { setAdd, setIsLoading, setErrorMessage, indicatorGroupsAndTypes, setIndicators } = props;
    const { instance } = useMsal();

    const errorText = 'Field is required';
    const [indicator, setIndicator] = useState({});
    const [open, setOpen] = React.useState(true);
    const [nameIsValid, setNameIsValid] = useState(true);
    
    const [nameError, setNameError] = useState('');
    const [indicatorGroupError, setIndicatorGroupError] = useState('');
    const [typeError, setTypeError] = useState('');

    function mapTypesToOptions(types) {
        return types.map((type) => ({
          label: type,
          value: type,
        }));
    }

    const indicatorGroups = [...new Set(indicatorGroupsAndTypes.map((item) => item.name))];

    const handleFieldChange = (event, field) => {
        if(field === 'name' && nameIsValid === false) {
          setNameIsValid(true);
          setNameError('');
        }
        else if(field === 'type') {
          setTypeError('');
        }
        else if(field === 'indicatorGroup') {
          setIndicatorGroupError('');
        }
        
        if(field === 'isOptional') {
          setIndicator({
            ...indicator,
            [field]:event.target.checked
          });
        }
        else {
          setIndicator({
              ...indicator,
              [field]:event.target.value
          }); 
        }
        console.log(indicator);
    }

    const fieldsAreValid = () => {
      let fieldsAreValid = true;
      if(indicator.name == null || indicator.name.trim() === '') {
        setNameIsValid(false);
        setNameError(errorText);
        fieldsAreValid = false;
      }
      if(indicator.indicatorGroupName == null || indicator.indicatorGroupName.trim() === '') {
        setIndicatorGroupError(errorText);
        fieldsAreValid = false;
      }
      if(indicator.type == null || indicator.type.trim() === '') {
        setTypeError(errorText);
        fieldsAreValid = false;
      }
      return fieldsAreValid;
    }

    const handleSave = () => {
      console.log(indicator);
      if(!fieldsAreValid()) {
        return;
      }
      
      getIndicatorByNameTypeAndReg(indicator.name, indicator.type, indicator.indicatorGroupName)
      .then((response) => {
        if(response.indicatorExists != null && response.indicatorExists === false) {
          setIsLoading(true);
          var trimmedindicator = Object.entries(indicator).reduce((acc, [key, value]) => {
            acc[key] = typeof value === 'string' ? value.trim() : value;
            return acc;
          }, {});
          addIndicator(trimmedindicator, instance.getActiveAccount().username)
          .then(() => {
            getIndicators()
            .then((response) => {
              setIndicators([...response]);              
              setIsLoading(false);
              setAdd(false);
            }).catch((e) => {
              setIsLoading(false);
              setAdd(false);
              console.log(e)
              setErrorMessage(errorMessageToDisplay);
            }) 
          })
          .catch((e) => {
            setIsLoading(false);
            console.log(e)
            setErrorMessage(errorMessageToDisplay);
          }) 
        }
        else {
          setNameIsValid(false);
          setNameError('Combination of name, type and indicator group already exists');
          return;
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e)
        setErrorMessage(errorMessageToDisplay);
      });                
    }
  
    const handleClose = () => {
      setAdd(false);
    }

    const typesToDisplay = (indicatorGroupName) => {
      if(indicatorGroupName == null || indicatorGroupName.trim() === '') {
        indicatorGroupName = indicatorGroups[0];
      }
      // console.log(indicatorGroupsAndTypes);
      const filteredItems = indicatorGroupsAndTypes.filter((item) => item.name === indicatorGroupName);
      return filteredItems.length > 0 ? filteredItems[0].types : [];
    }

    return (
        <>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            fullWidth={true}
            maxWidth="lg"
          >
            <DialogTitle>{"Indicator Details"}</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3}><label><b>Indicator Group:</b></label></Grid>
                  <Grid item xs={12} sm={9}><DropdownField options={mapTypesToOptions(indicatorGroups)} errorText={indicatorGroupError} onChange={(event) => handleFieldChange(event, 'indicatorGroupName')} /></Grid>
                  <Grid item xs={12} sm={3}><label><b>Type:</b></label></Grid>
                  <Grid item xs={12} sm={9}><DropdownField options={mapTypesToOptions(typesToDisplay(indicator.indicatorGroupName))} errorText={typeError} onChange={(event) => handleFieldChange(event, 'type')} /></Grid>
                  <Grid item xs={12} sm={3}><label><b>Name:</b></label></Grid>
                  <Grid item xs={12} sm={9}><TextField required error={!nameIsValid} helperText={nameError} value={indicator.name != null ? indicator.name : null} fullWidth onChange={(event) => handleFieldChange(event, 'name')}/></Grid>
                  <Grid item xs={12} sm={3}><label><b>Optional:</b></label></Grid>
                  <Grid item xs={12} sm={9}><Checkbox checked={indicator.isOptional != null ? indicator.isOptional : false} onChange={(event) => handleFieldChange(event, 'isOptional')} inputProps={{ 'aria-label': 'primary checkbox' }}/></Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>                    
          </Dialog>
        </>
      );

});

export default AddIndicator;