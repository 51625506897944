import React from 'react';
import { Grid } from '@mui/material';
import MetricDetailGridItem from './MetricDetailGridItem';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AuditInfoTooltip } from './AuditInfoTooltip';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MetricDetail = React.memo((props) => {
    const { metric, open, setOpen, setEdit, user } = props;
  
    const handleClose = () => {
      setOpen(false);
    }
  
    const handleEdit = () => {
      setEdit(true);
    }

    const metricSourceFields = (metric) => {
      return {
        "createdDate": metric.sourceFieldsCreatedDate,
        "lastUpdatedDate": metric.sourceFieldsLastUpdatedDate,
        "lastUpdatedBy": metric.sourceFieldsLastUpdatedBy
      }
    }
      
      return (
        <>
          <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          fullWidth={true}
          maxWidth="lg"
        >
          <DialogTitle><AuditInfoTooltip row={metric}/>{" Metric Detail"}</DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              <MetricDetailGridItem label="Name:" value={metric.name} />
              <MetricDetailGridItem label="Display Name:" value={metric.displayName} />
              <MetricDetailGridItem label="Value Unit Type:" value={metric.valueUnitType} />
              <br/>
              <br/>
              <Grid item sm={3}>
                <AuditInfoTooltip row={metricSourceFields(metric)}/>  Source Fields
              </Grid>
              <Grid item sm={9}></Grid>
              <MetricDetailGridItem label="Source Product Suite:" value={metric.sourceProductSuite} />
              <MetricDetailGridItem label="Metric Value Source Field:" value={metric.sourceDataProductMetricTypeCode} />
              <MetricDetailGridItem label="Metric Value Type:" value={metric.metricValueType} />
            </Grid>
          </DialogContent>
          <DialogActions>
            {
              user.hasMaintainerRole === true 
            ?
              <Button onClick={handleEdit}>Edit</Button>
            :
              <></>
            }
            <Button onClick={handleClose}>Close</Button>          
          </DialogActions>
        </Dialog>
        </>
      );
  });

export default MetricDetail;